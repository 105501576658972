<template>
    <div class="flex flex-col items-start gap-4">
        <div class="flex items-center gap-8 font-gibson text-20 font-medium leading-26">
            {{ unitPrice }}
            <ProductDetailsPriceAdditionalShippingInfo
                v-if="!isSticky && additionalShippingInfo && isEcomMarket"
                :details="details"
                class="text-14 leading-16"/>
        </div>
        <div v-if="comparisonPrice || ecoChargePrice || additionalShippingInfo" class="flex flex-col items-start">
            <div v-if="comparisonPrice" class="font-gibson text-12 font-light leading-16 text-brown-60">
                {{ comparisonPrice }}
            </div>
            <ProductDetailsPriceEcoChargeInfo v-if="ecoChargePrice" :selected-variant="selectedVariant"/>
            <ProductDetailsPriceAdditionalShippingInfo
                v-if="isSticky && additionalShippingInfo && isEcomMarket"
                :details="details"
                class="font-gibson text-12 font-light leading-16 text-brown-60"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';
import ProductDetailsPriceAdditionalShippingInfo from '@/project/product/details/ProductDetailsPriceAdditionalShippingInfo.vue';
import ProductDetailsPriceEcoChargeInfo from '@/project/product/details/ProductDetailsPriceEcoChargeInfo.vue';
import { v4 } from '@/types/serverContract';
import serverContext from '@/core/serverContext.service';

const props = defineProps<{
    details: v4.Products.ProductDetails;
    selectedVariant: v4.Products.ProductDetailsVariant;
    isSticky?: boolean;
}>();

const additionalShippingInfo = computed(() => props.details.additionalShippingInfo);
const unitPrice = computed(() => props.selectedVariant.pricing.unit.formatted);
const comparisonPrice = computed(() => props.selectedVariant.pricing.comparison?.formatted);
const ecoChargePrice = computed(() => props.selectedVariant.pricing.ecoCharge?.formatted);
const isEcomMarket = computed(() => serverContext.checkoutContext.hasCheckout);
</script>
