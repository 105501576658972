<template>
    <li :class="footerLink ? '' : 'border-b border-sand-40'">
        <RouterLinkConditional v-if="href" class="flex items-center"
                               :class="footerLink ? 'h-35' : 'ml-0 h-48'"
                               :to="href"
                               @click.native="action">
            <c-icon v-if="iconName" :name="iconName" class="mr-12" height="16px"
                    width="16px"/>
            <div v-if="image"
                 class="mr-8">
                <responsive-icon
                    :image-url="image.url"
                    :width="60"
                    :height="60"
                    :alt="image.name"/>
            </div>
            <span class="text-12 uppercase font-normal text-brown-80">{{ text }}</span>
        </RouterLinkConditional>
        <button v-else class="w-full flex items-center"
                :class="footerLink ? 'h-40' : 'h-48'"
                :aria-label="ariaLabel"
                @click="action()">
            <span class="text-12 uppercase font-normal">{{ text }}</span>
            <c-icon name="chevron"
                    width="14px"
                    class="ml-auto mr-10 u-trans-transform u-rotate-180 relative -top-1"/>
        </button>
    </li>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Media } from '@/types/serverContract';
import ResponsiveIcon from '@/core/responsive/image/ResponsiveIcon.vue';

@Component({
    components: {
        ResponsiveIcon
    }
})
export default class NavigationItem extends Vue {
    @Prop({ type: String })
    href!: string;

    @Prop({ type: String })
    text!: string;

    @Prop({ type: Function, default: () => {} })
    action!: Function;

    @Prop({ type: String })
    ariaLabel!: string;

    @Prop({ type: Boolean, default: false })
    footerLink!: boolean;

    @Prop({ type: String })
    iconName!: string;

    @Prop({ type: Object })
    image!: Media;
}
</script>
