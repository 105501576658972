<template>
    <div>
        <HorizontalContentSlider
            :key="selectedVariant.sku"
            :active-index="activeIndex"
            :slides="slides"
            class="relative"
            @slideChanged="onSlideChange">
            <template #default>
                <ProductDetailCarouselSlide
                    v-for="(media, i) in selectedVariant.sortedMedia"
                    :key="`heroCarouselSlide${i}`"
                    :details="details"
                    :selected-variant="selectedVariant"
                    :enable-favorited-by-badge="true"
                    :media="media"
                    :width-on-screen="{ xs: 100, lg: 50, xl: 80 }"
                    :height="520"
                    :width="390"
                    :is-video="isVideo(media)"
                    :index="i"
                    class="w-full"/>
                <transition
                    mode="out-in"
                    leave-active-class="animated fadeOut u-anim-dur-200"
                    enter-active-class="animated fadeIn u-anim-dur-200">
                    <button
                        v-if="shouldShowPlayIcon"
                        key="PlayVideoButton"
                        aria-label="generic.Open"
                        class="absolute bottom-0 left-0 z-1 mb-16 ml-16"
                        @click="goToFirstVideo">
                        <cIcon name="play-video" original class="h-30 w-30 text-brown-80"/>
                    </button>
                </transition>
            </template>
            <ProductDetailsFavoriteToggle slot="carousel-dots" :details="details" :selected-variant="selectedVariant" type="PDP"/>
        </HorizontalContentSlider>
        <ProductDetailsFullscreenImages
            :details="details"
            :selected-variant="selectedVariant"
            :active-index="activeIndex"/>
    </div>
</template>

<script setup lang="ts">
import { defineProps, computed, watch } from 'vue';
import ProductDetailCarouselSlide from './ProductDetailCarouselSlide.vue';
import { v4 } from '@/types/serverContract';
import ProductDetailsFavoriteToggle from '@/project/product/details/ProductDetailsFavoriteToggle.vue';
import ProductDetailsFullscreenImages from '@/project/product/details/ProductDetailsFullscreenImages.vue';
import HorizontalContentSlider from '@/project/shared/HorizontalContentSlider.vue';

import useVideo from '@/project/product/details/useVideo';

const props = defineProps<{
    details: v4.Products.ProductDetails;
    selectedVariant: v4.Products.ProductDetailsVariant;
    hasVideo: boolean;
}>();

const { onSlideChange, goToFirstVideo, shouldShowPlayIcon, activeIndex, isVideo, setVideoIndex } = useVideo(props.selectedVariant.sortedMedia);

setVideoIndex(props.selectedVariant.sortedMedia);

const slides = computed(() => {
    return props.selectedVariant?.sortedMedia.map((v, i) => i);
});

watch(
    () => props.selectedVariant.sku,
    (newVal, oldVal) => {
        if (newVal !== oldVal) {
            onSlideChange(0);
            setVideoIndex(props.selectedVariant.sortedMedia);
        }
    }
);
</script>
