<template>
    <div class="c-separator">
        {{ $translate('store.StoreSearchForm.InputSubmitSeperator') }}
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class HorizontalLine extends Vue {
};
</script>

<style lang="less">
.c-separator {
    display: flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    &:before,
    &:after {
        content: '';
        flex: 1;
        border-bottom-width: 1px;
        border-bottom-color: currentColor;
        border-bottom-style: solid;
        opacity: .2;
    }
        &:before {
        margin-right: 1.5rem;
    }
        &:after {
        margin-left: 1.5rem;
    }
}
</style>
