<template>
    <article v-click-outside="closeDropdown">
        <div v-if="options" class="pt-10 static md:relative">
            <header class="relative" :class="{ 'z-4' : dropDownActive }">
                <button class="c-btn c-btn--clean w-full mb-5 md:mb-10"
                        :title="$translate('search.Filters.Sort')"
                        :aria-label="$translate('search.Filters.Sort')"
                        @click="toggleDropdown">
                    <span class="c-btn__text font-bold text-10 md:text-13">{{ $translate('search.Filters.Sort') }}</span>
                    <span class="c-btn__text font-normal ml-5 text-10 md:truncate md:text-13 md:leading-footer">{{ activeDisplayName }}</span>
                    <span class="c-btn__icon ml-auto md:ml-0">
                        <span class="block u-trans u-trans-transform" :class="{'u-rotate-180' : dropDownActive}">
                            <c-icon name="chevron" width="11" class="u-reverse-rotate-90"/>
                        </span>
                    </span>
                </button>
                <span class="block border-t border-white-200"/>
            </header>
            <transition enter-active-class="animated u-fade-in-down u-anim-dur-200" leave-active-class="animated u-fade-out-up u-anim-dur-200">
                <div v-if="dropDownActive" class="absolute inset-0 z-3 w-full px-10 md:px-0">
                    <div class="bg-white-100 -mx-12 p-10 border border-white-200">
                        <ul class="pt-70">
                            <li v-for="option in options"
                                :key="option.id"
                                class="u-trans u-trans-bg"
                                :class="{'bg-white-300' : activeKey === option.id}"
                                :aria-checked="activeKey === option.id ? 'true' : 'false'"
                                :aria-label="$translate(option.label)"
                                :title="$translate(option.label)">
                                <input :id="'sorting_' + option.id"
                                       v-model="activeSorting"
                                       class="hidden"
                                       type="checkbox"
                                       :name="'sorting_' + option.id"
                                       :value="option.id"
                                       @change="onChange">
                                <label :for="'sorting_' + option.id"
                                       class="flex items-center justify-between cursor-pointer uppercase text-12 font-brandon tracking-medium p-7">
                                    {{ $translate(option.label ) }}
                                    <span class="opacity-0 u-trans u-trans-opacity" :class="{'opacity-100' : activeKey === option.id}">
                                        <c-icon name="checkmark" width="10"/>
                                    </span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </transition>
        </div>
    </article>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import urlHelper from '../facets/urlHelper.service';
import { Prop } from 'vue-property-decorator';
import dictionary from '@/core/translation/dictionary.service';
import trackingUtils from '@/project/tracking/tracking.utils';

@Component
export default class SearchSorting extends Vue {
    activeSorting: string | null = null;

    dropDownActive: boolean = false;

    @Prop({
        type: String,
        required: true
    })
        activeKey!: string;

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
        isSearchResults!: boolean;

    options = [
        {
            id: 'score',
            label: 'search.Sorting.Relevance'
        },
        {
            id: 'popularity',
            label: 'search.Sorting.Popularity'
        },
        {
            id: 'recent',
            label: 'search.Sorting.Recent'
        },
        {
            id: 'priceDesc',
            label: 'search.Sorting.PriceDescending'
        },
        {
            id: 'priceAsc',
            label: 'search.Sorting.PriceAscending'
        }];

    get activeDisplayName(): string {
        if (!this.activeKey) return ''; // initially empty, until we get answer from server
        const activeChoice = this.options.find(o => o.id === this.activeKey);
        if (!activeChoice) throw new Error('Got unknown sort-key: ' + this.activeKey);
        return dictionary.get(activeChoice.label);
    }

    onChange(event) {
        urlHelper.setSortBy(event.target.value);
        this.toggleDropdown();
        trackingUtils.facetFilter.trackSortingChange(event.target.value, this.activeKey);
    }

    toggleDropdown(): void {
        this.dropDownActive = !this.dropDownActive;
    }

    closeDropdown(): void {
        this.dropDownActive = false;
    }
}
</script>

<style lang="less" scoped>
</style>
