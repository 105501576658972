<template>
    <div class="o-container o-content-spot">
        <div class="md:flex md:items-center relative" :class="{'mt-30 md:mt-0': content.icon}">
            <div class="o-flex-grid md:w-2/3 md:ml-auto o-content-spot_images">
                <div class="o-flex-grid-item o-content-spot_secondary-image pt-30 md:flex md:flex-1 md:items-center">
                    <responsive-image v-if="content.secondaryImage && !(productId)"
                                      class="w-full"
                                      :width-on-screen="{md:25, xs:50}"
                                      :aspect-ratio="311/400"
                                      :image-url="content.secondaryImage.url"
                                      :focal-point="content.secondaryImage && content.secondaryImage.focalPoint"
                                      :alt="altText"/>
                    <div v-if="productId" class="w-full md:flex md:flex-1 md:items-center">
                        <product-provider v-slot="{ product }"
                                          :product-id="productId">
                            <ProductTile v-if="product"
                                         :product="product"
                                         :bg-theme="content.backgroundColor"
                                         :override-image="content.secondaryImage"
                                         :variant="productVariantSku"
                                         :image-mode="content.secondaryImage ? 'crop': null"
                                         class="w-full"
                                         :tracking-list-title="content && (content.title || content.supplementaryTitle)"/>
                            <product-skeleton v-else :bg-theme="content.backgroundColor"/>
                        </product-provider>
                    </div>
                </div>
                <div class="o-flex-grid-item o-content-spot_primary-image md:items-center">
                    <responsive-image v-if="content.mainImage"
                                      :width-on-screen="{md:33, xs:50}"
                                      :aspect-ratio="446/664"
                                      :image-url="content.mainImage.url"
                                      :focal-point="content.mainImage && content.mainImage.focalPoint"
                                      :alt="altText"/>
                </div>
            </div>
            <div class="o-content-spot_body md:relative md:w-1/3 md:pl-60 md:mr-auto lg:pl-70 xl:pl-90 lg:pt-50 md:mt-40 lg:mb-auto lg:mt-120">
                <div v-if="content.icon" class="absolute o-content-spot_icon">
                    <responsive-icon-wrap class="max-w-100 lg:max-w-250" :image="content.icon" :alt-text="altText" :bg-color="content.backgroundColor || ''"/>
                </div>
                <div class="o-content-spot_body-text-wrap mt-20 md:mt-40 lg:mt-0 pt-3 lg:pt-5 md:relative">
                    <component :is="getHeaderTagType" :id="getHeaderAnchorLink" class="mb-18 mr-20 lg:mb-32">
                        <div v-if="content.supplementaryTitle" class="o-content-spot_body-text-script font-script text-40 md:text-55 relative z-1 mb-0 -ml-10 md:-mb-8">
                            {{ content.supplementaryTitle }}
                        </div>
                        <div v-if="content.title"
                             class="o-content-spot_body-text-bold font-brandon leading-none tracking-normal text-26 md:text-40 uppercase relative z-1">
                            {{ content.title }}
                        </div>
                    </component>
                    <p v-if="content.text" class="o-content-spot_body-text leading-sm text-15 pt-3 mb-8 lg:pt-15" v-html="textWithBrTags"/>
                    <router-link-conditional v-if="content.link"
                                             :to="content.link.url"
                                             :target="content.link && content.link.target"
                                             class="c-btn c-btn--themed c-btn--sm inline-flex md:mt-40 font-brandon text-10"
                                             @click.native="trackPromotionClick">
                        <span class="c-btn__text font-light">{{ content.link.name }}</span>
                    </router-link-conditional>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop, Mixins } from 'vue-property-decorator';
import { ContentSpotBlockModel } from '@/types/serverContract';
import { newLineToBr, getHeaderAnchorLink } from '@/project/shared/string.util';
import PromotionTrackingMixin from '@/project/tracking/promotionTracking.mixin.vue';
import ProductSkeleton from '@/project/product/ProductSkeleton.vue';

@Component({
    name: 'BlockContentSpot',
    components: {
        ProductSkeleton
    }
})
export default class BlockContentSpot extends Mixins<PromotionTrackingMixin<ContentSpotBlockModel>>(PromotionTrackingMixin<ContentSpotBlockModel>) {
    trackingPositionId = 1; // Used for tracking

    get textWithBrTags() : string {
        return newLineToBr(this.content.text);
    }

    get getHeaderTagType() : string {
        return this.content.headerType || 'h3';
    }

    get getHeaderAnchorLink(): string | undefined {        return getHeaderAnchorLink(this.content);
    }

    get altText(): string | null {
        return this.content.title || this.content.supplementaryTitle ? [this.content.title, this.content.supplementaryTitle].join(' - ') : null;
    }

    get productId(): string | null {
        return this.content.productId || null;
    }

    get productVariantSku(): string | null {
        return this.content.sku || null;
    }
}
</script>

<style lang="less" scoped>
    .o-content-spot {
        overflow-x: hidden;
    }

    .o-content-spot_primary-image {
        width: 50%;
    }

    .o-content-spot_secondary-image {
        width: 50%;
    }

    .o-content-spot_icon {
        right: -3rem;
        top: -2.5rem;
        /deep/ .c-lottie {
            width: 9rem;
        }
    }

    .o-content-spot_body {
    }

    .o-content-spot_body-text-wrap {
        h3 {
            max-width: 24rem;
        }

        p {
            max-width: 32rem;
        }
    }
    .o-content-spot_body-text-script {}
    .o-content-spot_body-text-bold {
        line-height: 1.08;
    }
    .o-content-spot_body-text {
        letter-spacing: -0.1px;
        line-height: 1.33;
    }

    @screen md {
        .o-content-spot_images {
            max-width: 90rem;
        }
        .o-content-spot_primary-image {
            width: 67%;
        }

        .o-content-spot_secondary-image {
            width: 33%;
        }

        .o-content-spot_icon {
            top: 1.5rem;
            left: -2rem;
            right: auto;
        }

        .o-content-spot_body {
            position: relative;
        }

        .o-content-spot_body-text-wrap {
            h3 {
                max-width: none;
            }

            p {
                max-width: none;
            }
        }
    }
    @screen lg {
        .o-content-spot_images {
            max-width: 77rem;
        }
        .o-content-spot_secondary-image {
            width: 64%;
            padding-left: 0;
        }
        .o-content-spot_primary-image {
            width: 19%;
            flex-grow: 1;
        }
        .o-content-spot_icon {
            z-index: 1;
            right: auto;
            top: -7.5rem;
            left: -1rem;
        }
        .o-content-spot_body {
            width: 41%;
        }
        .o-content-spot_body-text-wrap {
            h3 {
                max-width: 28rem;
            }

            p {
                max-width: none;
            }
        }
        .o-content-spot_body-text-bold {
            line-height: 1.03;
        }
        .o-content-spot_body-text {
            line-height: 1.4;
            letter-spacing: .3px;
        }
    }
    @screen xl {
        .o-content-spot_icon {

        }
    }
</style>
