import { ref } from 'vue';
import { GrowthBook } from '@growthbook/growthbook';
import mParticleUtils from '../tracking/mParticle.utils';

/**
 * Configuration for the options passed to the app.use() call
 */
type GrowthBookConfig = {
    /**
     * The endpoint that your features are hosted on. Get this from the Environments -> SDK Endpoints section
     */
    featuresEndpoint: string;

    /**
     * Allows you to use the Chrome DevTools Extension to test/debug.
     * Learn more: https://docs.growthbook.io/tools/chrome-extension
     */
    enableDevMode: boolean;
};

const growthBook = ref<GrowthBook<Record<string, any>> | null>(null);
const pending = ref(false);
const enableDevMode: GrowthBookConfig['enableDevMode'] = import.meta.env.MODE !== 'production';
const featuresEndpoint: GrowthBookConfig['featuresEndpoint'] = enableDevMode ? import.meta.env.VITE_VUE_APP_GROWTHBOOK_FEATURES_ENDPOINT_DEV : import.meta.env.VITE_VUE_APP_GROWTHBOOK_FEATURES_ENDPOINT || '';
const featuresResponse = ref<Promise<Record<string, any>> | null>(null);
const getFeatures = async() => {
    pending.value = true;
    try {
        const response = await fetch(featuresEndpoint);
        const json = await response.json();
        featuresResponse.value = json;
    } catch (e) {
        console.log('Error getting GrowthBook features', e);
    }
    pending.value = false;
};

export default function useGrowthBook() {
    const init = async(): Promise<GrowthBook<Record<string, any>> | null> => {
        try {
            if (growthBook.value) {
                const result = await growthBook.value;
                return result as GrowthBook<Record<string, any>>;
            }
            growthBook.value = new GrowthBook({
                enableDevMode: enableDevMode,
                trackingCallback: (experiment, result) => {
                    mParticleUtils.navigation.trackGrowthBookExposure(experiment, result);
                }
            });

            const response = await featuresResponse.value;
            growthBook.value.setFeatures(response?.features);

            growthBook.value.setAttributes(
                {
                    ...mParticleUtils.store.growthbookAttributes()
                }
            );

            return growthBook.value as GrowthBook<Record<string, any>>;
        } catch (e) {
            console.error('GrowthBook error', e);
            return null;
        }
    };

    return {
        init,
        getFeatures,
        featuresResponse,
        pending
    };
}
