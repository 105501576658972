<template>
    <div class="faq w-full">
        <cIcon class="m-auto block w-88 pb-42 pt-50" name="faq"/>
        <div class="font-brandon-medium mb-56 text-center text-16 uppercase leading-22">
            {{ $translate('checkout.Basket.AnyQuestions') }}
        </div>
        <div class="border border-color-theme w-full border-b-0 border-l-0 border-r-0">
            <Accordion
                v-for="(item, index) in getFooterInformation"
                :key="index"
                :aria-label-item-description="item.title"
                :eightpxgrid="true"
                @open="trackAccordionOpen(item.title || `block-${index}`)">
                <div slot="header" class="font-brandon-regular w-full pl-0 text-12 leading-16 md:text-12">
                    {{ item.title }}
                </div>
                <slot>
                    <UmbracoText
                        :text="item.text"
                        tag="div"
                        :wysiwyg="true"
                        class="font-gibson-light leading-24 text-16 leading-20 md:text-14"/>
                </slot>
            </Accordion>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import serverContext from '@/core/serverContext.service';
import trackingUtils from '@/project/tracking/tracking.utils';
import Accordion from '@/project/shared/Accordion.vue';

@Component({
    components: {
        Accordion
    }
})
export default class FAQ extends Vue {
    get getFooterInformation() {
        return serverContext.checkoutContext.footerInformation;
    }

    public trackAccordionOpen(accordionTitle: string) {
        trackingUtils.overlay.trackAccordionOpen(accordionTitle);
    }
}
</script>

<style lang="less">
.faq .accordion.eightpxgrid .body-inner {
    @apply pt-0;
}
</style>
