<template>
    <div ref="pageFooterRef">
        <div v-if="pageDisclaimer" class="o-container py-40" role="contentinfo">
            <hr class="text-brown-80">
            <div
                v-delegate-html-links
                class="o-page-footer__disclaimer wysiwyg text-14 text-brown-50"
                v-html="pageDisclaimer"/>
        </div>
        <footer class="c-footer" role="navigation" :aria-label="$translate('footer.AriaLabel')">
            <div class="bg-white-125 pt-45">
                <div class="o-container">
                    <div class="flex flex-wrap lg:px-10% print:hidden">
                        <div
                            v-for="(linkCol, ix) in footerData.linkGroupAboveGraphics"
                            :key="ix"
                            class="c-footer__link-list--popular-categories">
                            <div v-if="linkCol.title" class="c-footer__header--popular-categories">
                                <span>{{ linkCol.title }}</span>
                            </div>
                            <ul v-if="linkCol.links" class="mb-10">
                                <li v-for="(linkItem, ix2) in linkCol.links" :key="ix2">
                                    <RouterLinkConditional
                                        :to="linkItem.url"
                                        :rel="linkCol.noFollow ? 'nofollow' : null"
                                        :title="linkItem.name"
                                        :target="linkItem.target">
                                        {{ linkItem.name }}
                                    </RouterLinkConditional>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <LazyLoaded :offset="1000">
                        <Breakpoints class="mt-70 lg:mt-70 print:hidden">
                            <div slot="max-md">
                                <img
                                    :src="footerData.graphicsMobile && footerData.graphicsMobile.url"
                                    :alt="footerData.graphicsMobile && footerData.graphicsMobile.name"
                                    width="100%">
                            </div>
                            <div slot="min-lg">
                                <img
                                    :src="footerData.graphicsDesktop && footerData.graphicsDesktop.url"
                                    :alt="footerData.graphicsMobile && footerData.graphicsMobile.name"
                                    width="100%">
                            </div>
                        </Breakpoints>
                    </LazyLoaded>
                </div>
            </div>
            <div class="bg-brown-80 pb-50">
                <div class="o-container">
                    <div class="pt-55 lg:pt-35 print:hidden">
                        <Breakpoints v-if="footerData.linkGroupBelowGraphics">
                            <div slot="max-md">
                                <Accordion
                                    v-for="(linkCol, ix) in footerData.linkGroupBelowGraphics"
                                    :key="ix"
                                    :aria-label-item-description="linkCol.title">
                                    <slot slot="header">
                                        <span class="font-normal">{{ linkCol.title }}</span>
                                    </slot>
                                    <slot slot="default">
                                        <ul v-if="linkCol.links" class="mb-20 px-10 text-16">
                                            <li v-for="(linkItem, ix2) in linkCol.links" :key="ix2" class="mb-15">
                                                <RouterLinkConditional
                                                    :to="linkItem.url"
                                                    :rel="linkCol.noFollow ? 'nofollow' : null"
                                                    :title="linkItem.name"
                                                    :target="linkItem.target">
                                                    {{ linkItem.name }}
                                                </RouterLinkConditional>
                                            </li>
                                        </ul>
                                    </slot>
                                </Accordion>
                            </div>
                            <div slot="min-lg" class="flex px-10%">
                                <div
                                    v-for="(linkCol, ix) in footerData.linkGroupBelowGraphics"
                                    :key="ix"
                                    class="c-footer__link-list">
                                    <div v-if="linkCol.title" class="c-footer__header">
                                        <span>{{ linkCol.title }}</span>
                                    </div>
                                    <ul v-if="linkCol.links" class="mb-40">
                                        <li v-for="(linkItem, ix2) in linkCol.links" :key="ix2">
                                            <RouterLinkConditional
                                                :to="linkItem.url"
                                                :rel="linkCol.noFollow ? 'nofollow' : null"
                                                :title="linkItem.name"
                                                :target="linkItem.target">
                                                {{ linkItem.name }}
                                            </RouterLinkConditional>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Breakpoints>
                    </div>
                    <LazyLoaded :offset="1000">
                        <AppInfoAndMeetAnnaAndClara
                            class="print:hidden"
                            :anna-and-clara-block-link="footerData.annaAndClaraBlockLink"
                            :anna-and-clara-block-text="footerData.annaAndClaraBlockText"
                            :anna-and-clara-block-title="footerData.annaAndClaraBlockTitle"
                            :app-info-block-text="footerData.appInfoBlockText"
                            :app-info-block-image="footerData.appInfoBlockImage"/>
                    </LazyLoaded>
                    <button
                        v-tracking="{ trigger: 'click', event: 'overlay', overlayName: 'culturePicker' }"
                        type="button"
                        :aria-label="$translate('generic.AriaLabels.CulturePicker')"
                        class="mx-auto mb-40 mt-90 flex max-w-250 items-center justify-center px-20 font-light md:mt-0 print:hidden"
                        @click="openCulturePicker">
                        <cIcon name="globe" class="-mt-3 mr-10 h-18 w-22 lg:-mt-5"/>
                        <div class="tracking-small">
                            {{ $translate('culture.Culture') }}:
                            <span class="effect-underline leading-heading">{{ currentCultureName }}</span>
                        </div>
                    </button>
                    <div class="my-20 w-full text-center font-gibson text-white-400 lg:mb-30 lg:mt-40">
                        <PaymentMethodIcons context="PageFooter" class="mb-10 justify-center"/>
                        <div v-if="showSSLIcon" class="mb-24 mt-40">
                            <cIcon name="ssl" class="w-60"/>
                        </div>
                        <div class="c-footer__copyright mt-20 lg:mt-10">
                            {{ labelCopyright }}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { FooterNavigationModel } from '@/types/serverContract';
import ResponsiveIcon from '@/core/responsive/image/ResponsiveIcon.vue';
import bus from '@/core/bus';
import { FOOTER_IN_VIEW } from '@/project/config/constants';
import { openCulturePickerEventName } from '@/project/layout/culture/constants';
import cultureService from '@/project/layout/culture/culture.service';
import serverContext from '@/core/serverContext.service';
import translateFilter from '@/core/translation/translate.filter';
import spaStore from '@/core/spa/store/spa.store';
import productsStore from '@/store/products/products.store';
import Accordion from '@/project/shared/Accordion.vue';
import AppInfoAndMeetAnnaAndClara from '@/project/layout/page-footer/AppInfoAndMeetAnnaAndClara.vue';
import LazyLoaded from '@/project/shared/LazyLoaded.vue';
import PaymentMethodIcons from '@/project/shared/PaymentMethodIcons.vue';

@Component({
    components: {
        AppInfoAndMeetAnnaAndClara,
        Accordion,
        ResponsiveIcon,
        LazyLoaded,
        PaymentMethodIcons
    }
})
export default class PageFooter extends Vue {
    fullYear: number = new Date().getFullYear();

    isMounted = false;
    observer!: IntersectionObserver;

    public $refs!: {
        pageFooterRef: HTMLDivElement
    }

    mounted() {
        this.isMounted = true;
    }

    public destroyed() {
        this.isMounted = false;
    }

    observe(el: HTMLElement) {
        this.observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                bus.emit(FOOTER_IN_VIEW, true);
            } else {
                bus.emit(FOOTER_IN_VIEW, false);
            }
        });

        this.observer.observe(el);
    }

    unObserve(el: HTMLElement): void {
        if (this.observer) {
            bus.emit(FOOTER_IN_VIEW, null);
            this.observer.unobserve(el);
            this.observer.disconnect();
        }
    }

    get showSSLIcon(): boolean {
        return serverContext.market === 'DE';
    }

    get labelCopyright(): string {
        return translateFilter('footer.Copyright', this.fullYear.toString());
    }

    get footerData(): FooterNavigationModel {
        return serverContext.footerNavigation;
    }

    get currentCultureName(): string {
        const currentCulture = cultureService.serverCulture();
        return currentCulture ? currentCulture.name : '<Not set>';
    }

    openCulturePicker() {
        bus.emit(openCulturePickerEventName);
    }

    get pageDisclaimer(): null | string {
        if (spaStore.isPageProductRelated || this.anyProductsLoadedViaStore) {
            return serverContext.pageDisclaimer;
        }
        return null;
    }

    get anyProductsLoadedViaStore(): boolean {
        return productsStore.anyProductsLoadedViaStore;
    }

    get enableObserver() {
        return {
            mounted: this.isMounted,
            enable: spaStore.jsonContent.alias === 'productDetails',
            el: this.$refs.pageFooterRef
        };
    }

    @Watch('enableObserver', { immediate: true, deep: true })
    onEnableObserverChange({ enable, el }) {
        if (!el) {
            return;
        }

        if (enable) {
            this.observe(el);
        } else {
            this.unObserve(el);
        }
    }
}
</script>
