<template>
    <spa-server>
        <div class="mx-auto flex min-h-screen flex-col" :class="getWrapperClasses">
            <a href="#main" class="sr-only">{{ $translate('generic.AriaLabels.SkipToMainContent') }}</a>
            <culture-picker/>
            <template v-if="cultureIsSet">
                <page-header v-if="!isThunnelLayout" :class="getHeaderClasses"/>
                <main
                    id="main"
                    ref="main"
                    class="site-main print:pt-5"
                    role="main"
                    aria-live="polite"
                    :aria-label="labelMainElement"
                    :class="mainClasses">
                    <page-render/>
                </main>
                <PortalTarget name="pdp-sticky-cta" slim/>
                <page-footer v-show="!isThunnelLayout"/>
            </template>
            <portal-target name="overlay"/>
            <portal-target name="overlay2"/>
            <portal-target name="energy-label-overlay"/>
            <portal-target name="variant-picker-overlay"/>
            <portal-target name="ecocharge-overlay"/>
            <portal-target name="shipping-information-overlay"/>
            <portal-target name="specifications-overlay"/>
            <portal-target name="login"/>
            <portal-target name="password-reset-overlay"/>
            <portal-target
                name="countrySelect"/><!-- the country select can be inside another overlay, therefore: mush have both login and countrySelect targets -->
            <PortalTarget name="dropPoint"/>

            <BasketOverlay/>

            <portal-overlay
                ref="loginOverlay"
                :show.sync="loginActiveInternally"
                :disable-body-scroll="true"
                :show-close-button="false"
                :disable-user-close="true"
                :close-on-route-change="true"
                wrapper-class="flex bg-white-300"
                side="fade"
                portal="login">
                <!-- bg-white-300 on wrapper class is to make iOS 14 not showing bottom of login modal bad -->
                <LoginOverlay class="relative z-3" @clearShow="closeLogin"/>
            </portal-overlay>

            <VariantPickerOverlay/>
            <SpecificationsOverlay/>
            <ShippingInformationOverlay/>
            <EcoChargeOverlay/>
            <EnergyLabelOverlay/>
            <ReceiptResetPasswordOverlay/>

            <component
                :is="possibleConfetti"
                v-if="shouldConfettiLoad"
                :mode="confettiMode"
                :active="shouldConfettiLoad"
                :colors="birthdayConfettiColors"/>
        </div>
    </spa-server>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import SpaServer from '@/core/spa/SpaServer.vue';
import PageRender from '@/core/spa/PageRender.vue';
import PageFooter from '@/project/layout/page-footer/PageFooter.vue';
import PageHeader from '@/project/layout/page-header/PageHeader.vue';
import Breadcrumb from '@/project/shared/navigation/Breadcrumb.vue';
import navStore from '@/store/nav.store';
import spaStore from '@/core/spa/store/spa.store';
import basketStore from '@/store/basket.store';
import userStore from '@/store/user.store';
import serverContext from '@/core/serverContext.service';
import { confettiColorsByMarket, /* isNationalCelebrationDay, */ confettiModes } from '@/project/shared/string.util';
import BasketOverlay from '@/project/checkout/BasketOverlay.vue';
import VariantPickerOverlay from '@/project/product/variant/VariantPickerOverlay.vue';
import SpecificationsOverlay from '@/project/product/details/SpecificationsOverlay.vue';
import ShippingInformationOverlay from '@/project/product/ShippingInformationOverlay.vue';
import EcoChargeOverlay from '@/project/product/EcoChargeOverlay.vue';
import EnergyLabelOverlay from '@/project/product/EnergyLabelOverlay.vue';
import ReceiptResetPasswordOverlay from '@/project/checkout/receipt/ReceiptResetPasswordOverlay.vue';
import { prevRoute, router } from '@/core/spa/router/index';
import myFontCounter from '@/project/layout/css/myfonts';
export function loadConfetti() {
    return () => import('@/project/party/Confetti.vue');
}

@Component({
    components: {
        ShippingInformationOverlay,
        VariantPickerOverlay,
        EcoChargeOverlay,
        EnergyLabelOverlay,
        Breadcrumb,
        ReceiptResetPasswordOverlay,
        BasketOverlay,
        SpaServer,
        PageRender,
        PageHeader,
        PageFooter,
        SpecificationsOverlay
    }
})
export default class App extends Vue {
    cssProperty: string = 'min-height';
    delayUntilItIsSafeToClearMinHeight: number = 1500;
    delayUntilFadeoutCompleted: number = 300;
    persistHeightTimeout: number | undefined;
    showMain: boolean = true;
    loginActiveInternally: boolean = false;
    isNationalCelebrationDay: boolean = false;
    public $refs!: {
        main: HTMLElement;
        loginOverlay: any;
    };

    created() {
        const returnPath = this.$route.query.ReturnUrl?.toString();
        if (returnPath && returnPath !== this.$route.path && serverContext.hasLogin) {
            if (userStore.token) {
                setTimeout(() => {
                    this.$router.replace(returnPath);
                }, 200);
            } else {
                userStore.setLoginFormActiveState(true);
            }
        }
        // if (isNationalCelebrationDay(serverContext.market)) {
        //     setTimeout(() => this.isNationalCelebrationDay = true, 2000);
        // }
    }

    mounted() {
        // MyFont counter
        setTimeout(myFontCounter, 1000);
    }

    @Watch('loginFormActive')
    onloginFormActiveChange(loginFormActive: boolean) {
        this.loginActiveInternally = loginFormActive;
    }

    @Watch('loginActiveInternally')
    loginFormInternallyChange(state, oldState) {
        // When page blind sets loginForm to false via :show.sync="loginFormInternally", remember to update store.
        if (!state) {
            userStore.setLoginFormActiveState(state);
        }
    }

    get getNotificationBar() {
        return spaStore.notificationBar;
    }

    get basketActive() {
        return basketStore.basketActive;
    }

    get loginFormActive() {
        return userStore.loginFormActive;
    }

    get getWrapperClasses(): string {
        if (spaStore.isExperiencePage) {
            return 'bg-brown-80';
        }
        return '';
    }

    get getHeaderClasses(): string {
        return '';
    }

    get mainClasses(): string {
        if (this.isThunnelLayout) {
            return 'min-h-screen';
        }
        return `${navStore.hasHeroBlockAsFirstNavigation ? '' : 'xl:pt-20'}`;
    }

    get isThunnelLayout() {
        return spaStore.isThunnelLayout;
    }

    get cultureIsSet(): boolean {
        return !!serverContext.culture;
    }

    closeLogin(): void {
        if (spaStore.isThunnelLayout) {
            // In checkout, we should not go back to previous page
            userStore.setLoginFormActiveState(false);
            return;
        }
        // Go back one page, unless there is no previous page,
        // then go to frontpage
        if (prevRoute && prevRoute.path !== '/') {
            router.back();
        } else {
            router.push(serverContext.marketUrl);
        }
    }

    get shouldConfettiLoad(): boolean {
        return userStore.shouldConfettiLoad || this.isNationalCelebrationDay;
    }

    get possibleConfetti() {
        return this.shouldConfettiLoad ? loadConfetti() : 'div';
    }

    get birthdayConfettiColors(): string[] {
        const colors = confettiColorsByMarket(serverContext.market);
        if (!colors) {
            return ['#E9EB91', '#8ED0AD', '#F1B8B9', '#91A5D8']; // Fallback colors
            
        }
        return colors;
    }

    get confettiMode() {
        return confettiModes.nationalCelebration;
    }

    get labelMainElement(): string {
        return spaStore.mainAriaLabel || spaStore.metadata.navigationTitle;
    }

    isCheckout() {
        return serverContext.checkoutContext.checkoutUrl === this.$route.path;
    }
}
</script>

<style lang="less">
body {
    &.prevent-scroll {
        overflow: hidden;
        height: 100vh;
    }
}

.fadeIn-transition {
    transition: opacity 0.3s linear;
}

.fadeIn-enter,
.fadeIn-leave {
    opacity: 0;
}
</style>
