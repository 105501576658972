<template>
    <div class="c-favorites-count-badge absolute z-1 inline-flex h-24 items-center gap-8 bg-sand-40">
        <c-icon name="heart-filled" width="12" height="12"/>
        <span class="font-gibson text-14 font-normal leading-16">
            <Transition mode="out-in" name="fade">
                <span :key="formattedFavoriteCount">{{ formattedFavoriteCount }}</span>
            </Transition>
        </span>
    </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, ref, watch } from 'vue';
import favoritesStore from '@/store/favorites.store';
import { v4 } from '@/types/serverContract';

const props = defineProps<{
    favoritedBy?: v4.Products.ProductDetails['favoritedBy'];
    favoriteId: v4.Products.ProductDetailsVariant['sku'];
}>();

const formatter = Intl.NumberFormat('en', { notation: 'compact' });

const favoriteCount = ref(props.favoritedBy || 0);
const formattedFavoriteCount = computed(() => formatter.format(favoriteCount.value));
const favoritesCount = computed(() => favoritesStore.favorites?.length);
const isFavorite = computed(() => {
    if (!favoritesStore.favorites || favoritesStore.favorites.length === 0) {
        return false;
    }

    const found = favoritesStore.favorites.find((x) => x.identifier === props.favoriteId);
    if (!found) {
        return false;
    }

    return true;
});
watch([favoritesCount, isFavorite], ([newFavoritesCount, newVal], [oldFavoritesCount]) => {
    // only update when adding/removing from favorites - first time oldFavoritesCount will be `undefined`
    if (oldFavoritesCount === undefined) {
        return;
    }

    if (newVal === true) {
        favoriteCount.value += 1;
    } else {
        favoriteCount.value -= 1;
    }
});
</script>

<style scoped>
.c-favorites-count-badge {
    @apply right-0 rounded-l-2 px-8 pr-12;

    bottom: 1.9rem;
}

@screen md {
    .c-favorites-count-badge {
        @apply bottom-auto right-auto rounded-none px-8;
        @apply left-0 rounded-r-2 pl-12;

        top: 2.4rem;
    }
}
</style>
