<template>
    <div v-if="status !== 'ERROR'" ref="recommendationsRef">
        <div v-if="recommendations && recommendations.length > 0" class="o-container">
            <div
                v-for="(recommendation, recommendationIndex) in recommendations"
                :key="recommendation.type"
                class="flex flex-col gap-32 pb-48 pt-32">
                <h3
                    class="mb-0 font-brandon text-14 font-medium uppercase leading-16 md:text-20 md:font-normal md:leading-24">
                    {{ recommendation.name }}
                </h3>
                <ProductProvider v-slot="{ products, productsLoaded }" :product-ids="getProductIds(recommendation)">
                    <div v-if="status === 'LOADING' || !productsLoaded" class="-mx-8 flex overflow-hidden md:mx-0">
                        <div v-for="(n, nIx) in 4" :key="nIx">
                            <div class="w-221 px-8 md:mr-20 md:w-315 md:px-0">
                                <ProductSkeleton :aspect-ratio="250 / 321"/>
                            </div>
                        </div>
                    </div>
                    <div v-else class="-mx-20 md:mx-0">
                        <FlickityWrap
                            ref="flickityRef"
                            :options="getFlickityOptions(products.length)"
                            :scroll-wheel="false"
                            :handle-select="false"
                            class="h-full w-full">
                            <div
                                v-for="(product, productIndex) in products"
                                :key="`${uid}-${recommendationIndex}-${product.id}-${getProductVariant(recommendation, product.id)}`"
                                class="md:mr-20 md:w-315 md:px-0"
                                :class="{
                                    'w-233 px-8 pl-20': productIndex === 0,
                                    'w-233 px-8 pr-20': productIndex === products.length - 1,
                                    'w-221 px-8': productIndex !== 0 && productIndex !== products.length - 1
                                }">
                                <ProductTile
                                    v-if="product"
                                    :product="product"
                                    :variant="getProductVariant(recommendation, product.id)"
                                    :transparent-bg="true"
                                    :tracking-list-provider="'Recommendation'"
                                    :tracking-list-type="getTrackingListNameForRecommendationType(recommendation.type)"
                                    :tracking-list-title="recommendation.name"
                                    :tracking-index="productIndex"/>
                            </div>
                        </FlickityWrap>
                    </div>
                </ProductProvider>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, onMounted, ref, watch, getCurrentInstance } from 'vue';
import BreakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';
import FlickityWrap from '@/core/FlickityWrap.vue';
import ProductTile from '@/project/product/ProductTile.vue';
import ProductSkeleton from '@/project/product/ProductSkeleton.vue';
import { getTrackingListNameForRecommendationType } from '@/project/tracking/tracking.utils';
import { v4 } from '@/types/serverContract';
import { RecommendationsStatus } from '@/project/product/details/useRecommendations';
import type Flickity from 'flickity';

defineProps<{
    status: RecommendationsStatus;
    recommendations?: v4.Products.ProductRecommendationsIdOnly[] | null;
}>();

const emit = defineEmits(['inView']);

const activeBreakpoint = computed(() => BreakpointsState.activeBreakpoint);
const flickityRef = ref<FlickityWrap | FlickityWrap[] | null>(null);
const recommendationsRef = ref<HTMLElement | null>(null);
const inView = ref<boolean | null>(null);
const uid = (getCurrentInstance()?.proxy as any)._uid;

watch(activeBreakpoint, () => {
    if (!flickityRef.value) {
        return;
    }

    if (Array.isArray(flickityRef.value)) {
        flickityRef.value.forEach((flickity) => flickity.resize());
    } else {
        flickityRef.value.resize();
    }
});

const getFlickityOptions = (productCount: number): Flickity.Options => {
    const options: Flickity.Options = {
        arrowShape:
            'M89.6 97.3c.9-2.4.3-4.1-2.1-5.7-.7-.5-1.4-1-2-1.5-3-2.2-6.2-4.4-9.2-6.5-1.9-1.3-3.7-2.9-5.5-4.3-1.2-1-2.5-2.1-3.8-3.1l-.7-.6c-1.4-1-2.8-2.1-4-3.3l-1.9-1.7c-.9-.8-1.7-1.5-2.5-2.3-1.2-1.1-2.5-2.2-3.7-3.2-1.7-1.5-3.5-3-5-4.5-1-1-2-2-3-2.9-.9-.8-1.7-1.6-2.5-2.4-1.5-1.5-2.9-3-4.2-4.5-.6-.6-1.1-1.2-1.7-1.8-.2-.5-.5-.9-.9-1.3 0-.1-.4-.4-.8-.8l-.3-.3c.2-.2.4-.4.5-.6.6-.7 1.2-1.4 1.5-1.6 1.9-1.7 3.8-3.1 6.3-4.9 2.5-1.7 4.9-3.5 7.3-5.3 1.3-1 2.6-1.8 4-2.7l1.3-.8c1.2-.7 2.2-1.5 3.4-2.4l1.4-1 1.1-.7c1.4-1 2.8-2 4.1-3 1-.8 1.9-1.6 2.9-2.4.6-.5 1.3-1 1.9-1.6.8-.7 1.7-1.3 2.5-2 .6-.5 1.1-.9 1.7-1.3 1.4-1.1 2.7-2.1 4.1-3.1l1.4-1c.6-.4 1.2-.8 1.7-1.2.2-.2.5-.3.7-.5.6-.4 1.2-.8 1.8-1.3 1.1-1 2-1.9 2.1-3.9 0-.6.2-2.4-1.2-3.7-1-1-1.8-1.2-3.2-1.5h-.7c-1.3 0-2.5.5-3.6 1.4-.9.7-1.6 1.2-2.5 1.8l-.9.6c-1.6 1.1-3.2 2.3-4.7 3.5L69 8.7c-1.5 1.2-2.9 2.4-4.4 3.6-1.5 1.2-3 2.6-4.6 3.7-1 .8-2.1 1.5-3.1 2.2-.7.5-1.5 1-2.2 1.6-1.9 1.2-3.4 2.2-4.9 3.2-1.5 1-3.2 2.1-5.2 3.5-.7.5-1.5 1-2.2 1.6-.9.7-1.8 1.4-2.8 2l-.9.6c-1.3.9-2.7 1.9-4 2.9-1.3 1-2.5 2.1-3.8 3.2l-1 .9-3 2.7c-.3.3-.8.7-1.1 1.2-.7 1-1.5 2.2-1.5 3.7 0 1.4 0 3.2 1.4 4.9l.5.5.4.4c.5.7 1.1 1.3 1.7 1.9.5.6 1.1 1.1 1.7 1.5.8.9 1.7 1.7 2.5 2.7 1.8 2.1 3.9 4 5.9 5.9l1 .9c2.4 2.4 4.3 4.1 6.1 5.7.8.7 1.7 1.5 2.5 2.2.7.5 1.4 1.1 2 1.7 1 .8 1.9 1.7 2.8 2.6.5.5 1 1 1.6 1.4 1.8 1.6 3.7 3.1 5.6 4.5 1.3 1.1 2.7 2.1 4 3.2 3 2.5 6.2 4.8 9.7 7.4.5.3.9.7 1.4 1 1.2.8 2.2 1.6 3.3 2.4.3.2.6.5.9.7.7.6 1.5 1.3 2.3 1.9 1.1.7 2.4 1.2 3.7 1.2 2.1 0 3.7-1 4.3-2.8v.2z',
        contain: true,
        pageDots: false,
        groupCells: true
    };

    if (BreakpointsState.isBreakpointActive('max-sm') && productCount < 2) {
        options.cellAlign = 'left';
    } else if (BreakpointsState.isBreakpointActive('min-ls') && productCount < 4) {
        options.cellAlign = 'left';
    } else {
        options.cellAlign = 'center';
    }

    return options;
};

const getProductIds = (recommendation: v4.Products.ProductRecommendationsIdOnly) =>
    recommendation.products.map((p) => p.id);
const getProductVariant = (recommendation: v4.Products.ProductRecommendationsIdOnly, productId: string) =>
    recommendation.products.find((p) => p.id === productId)?.variantId || undefined;

const setupObserver = (el: HTMLElement) => {
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                inView.value = entry.isIntersecting;
            });
        },
        { rootMargin: '100px' }
    );

    observer.observe(el);
};

onMounted(() => {
    if (!recommendationsRef.value) return;
    setupObserver(recommendationsRef.value);
});

watch(inView, (inView) => emit('inView', inView), { immediate: true });
</script>

<style lang="less" scoped>
/deep/ .flickity-button {
    background: none;
    color: theme('colors.brown-80');

    &:disabled {
        display: none;
    }

    &:focus {
        box-shadow: none;
    }

    & .flickity-button-icon {
        position: static;
        width: 1.8rem;
        height: 1.8rem;
    }
}

/deep/ .flickity-prev-next-button {
    display: none;
    top: 40%;

    &.previous {
        left: 0;
    }

    &.next {
        right: 0;
    }
}

@screen md {
    /deep/ .flickity-prev-next-button {
        display: grid;
        place-items: center;
        width: 2.8rem;
        height: 2.8rem;

        &.previous {
            left: -3rem;
        }

        &.next {
            right: -3rem;
        }
    }
}

@screen xl {
    /deep/ .flickity-prev-next-button {
        &.previous {
            left: -3.8rem;
        }

        &.next {
            right: -3.8rem;
        }
    }
}
</style>
