<template>
    <div class="o-container py-20" :alias="alias" data-debug-text-block-fallback>
        <h3 v-if="content && contentTitle">
            {{ contentTitle }}
        </h3>
    </div>
</template>

<script setup lang="ts">
import { JsonContent } from '@/types/serverContract';

const props = defineProps<{
    content: JsonContent;
    alias: string;
}>()

const contentTitle = 'title' in props.content ? props.content.title : '';

</script>
