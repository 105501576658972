<template>
    <div class="o-container py-50">
        <h3 v-if="content.title" :id="getHeaderAnchorLink" class="font-brandon text-11 md:text-13 mb-15 md:mb-30 uppercase tracking-medium md:tracking-normal" :class="themeAlternateTextColor">
            {{ content.title }}
        </h3>

        <div class="o-grid c-product-list">
            <block-render v-for="(block, blockIndex) in getContentBlocks" :key="blockIndex" class="o-grid-item h-full" :block="isProductsLoaded && block"
                          :style="{order:getContentBlockPosition(block.content.position)}" :data-legacy-sort="getContentBlockPosition(block.content.position)"/>
            <product-provider v-for="(productListItem, productIndex) in content.products" :key="`${productListItem.productId}-${productIndex}`" v-slot="{ product }"
                              :change="debouncedProductsChange()" :product-id="productListItem.productId">
                <ProductTile v-if="isProductsLoaded && product" :product="product" class="o-grid-item"
                             :override-image="productListItem.image"
                             :image-mode="productListItem.image ? 'crop' : null"
                             :tracking-index="productIndex"
                             :image-alt="altText"
                             :variant="productListItem.sku"
                             :style="{order:productIndex}"
                             :data-legacy-sort="productIndex"
                             :bg-theme="content.backgroundColor"
                             :show-expired-text="true"
                             :use-environmental-images="content.useEnvironmentalImages"/>
                <product-skeleton v-else-if="!isProductsLoaded"
                                  :bg-theme="content.backgroundColor"
                                  class="o-grid-item"/>
            </product-provider>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ProductListBlockModel } from '@/types/serverContract';
import debounce from 'lodash-es/debounce';
import ProductSkeleton from '@/project/product/ProductSkeleton.vue';
import { styleFunctionsForThemedContentAlternate } from '@/project/shared/styleFunctionsForThemedContent';
import { getHeaderAnchorLink } from '@/project/shared/string.util';

@Component({
    name: 'BlockProductList',
    components: {
        ProductSkeleton
    }
})
export default class BlockProductList extends Vue {
    @Prop() content!: ProductListBlockModel;

    isProductsLoaded: boolean = false;
    // Bundle all product-change callbacks in this one
    debouncedProductsChange = debounce(this.productsLoadedHandler, 150);

    getContentBlockPosition(position) {
        return (position || 0) - 1;
    }

    get getContentBlocks() {
        /*
            Dont show content blocks that have index larger than the number of products + 1.
            So if content blocks index is set to 100 and only 50 products are shown - cause they are not valid anymore,
            the content block position 100 will not be shown.
        */

        return this.content.blocks.slice(0, this.content.products ? this.content.products.length : 0);
    }

    get getHeaderAnchorLink(): string | undefined {        return getHeaderAnchorLink(this.content);
    }

    get themeAlternateTextColor() {
        return styleFunctionsForThemedContentAlternate(this.content.textColor);
    }

    productsLoadedHandler() {
        this.isProductsLoaded = true;
    }

    get altText(): string | null {
        if (this.content.altText) {
            return this.content.altText;
        }
        return this.content.title;
    }
}
</script>
