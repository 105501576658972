<template>
    <article class="flex items-center">
        <span class="text-brown-500 text-12 uppercase">{{ translateFilter(`search.Filters.${facetKey}`) }}</span>
        <CheckboxSwitch v-model="toggled" class="ml-auto" @input="facetChange"/>
    </article>
</template>

<script setup lang="ts">
import { ref, toRef, watch, type Ref } from 'vue';
import urlHelper from '@/project/facets/urlHelper.service';
import trackingUtils from '@/project/tracking/tracking.utils';
import translateFilter from '@/core/translation/translate.filter';
import { FacetGroup } from '@/types/serverContract';

const props = defineProps<{
    facetGroup: FacetGroup | undefined;
}>();

const facetGroup = toRef(props, 'facetGroup');
const facetKey = props.facetGroup?.key ?? '';
const toggled = ref(getValueFromFacetGroup(facetGroup));

function facetChange() {
    trackingUtils.facetFilter.trackFacetAddOrRemove(toggled.value, facetKey, 'true', urlHelper.getFacets());
    urlHelper.updateFacetValue(facetKey, 'true', toggled.value);
}

watch(facetGroup, () => {
    toggled.value = getValueFromFacetGroup(facetGroup);
});

function getValueFromFacetGroup(facetGroup: Ref<FacetGroup| undefined>): boolean {
    if (!facetGroup.value) {
        return false;
    }
    return facetGroup.value.values.find(v => v.key === 'true')?.selected ?? false;
}
</script>
