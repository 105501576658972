<template>
    <div class="c-desktop-nav" role="navigation" :aria-label="$translate('navigation.AriaLabel')">
        <div class="c-desktop-nav__container o-container relative z-2 max-h-60 w-full lg:max-w-1440">
            <div class="c-desktop-nav__inner-container flex items-center justify-between">
                <div class="flex items-center">
                    <div class="c-desktop-nav__logo-collapsed relative top-4">
                        <router-link v-if="headerNavigation?.frontPage?.url"
                                     :to="headerNavigation.frontPage.url"
                                     :title="headerNavigation.frontPage.name"
                                     class="absolute inset-0"/>
                        <cIcon name="logo-collapsed" class="text-brown-80"/>
                    </div>
                    <div class="c-desktop-nav__list-wrapper ml-40 flex">
                        <ul v-if="headerNavigation" class="truncate ml-auto flex h-60 w-full">
                            <template v-for="(link, ix) in headerNavigation.links">
                                <li
                                    v-if="
                                        navigationService.isStandardLink(link.type) &&
                                            link.nameAndUrl &&
                                            link.nameAndUrl.url
                                    "
                                    :key="ix"
                                    class="relative h-full px-10">
                                    <router-link
                                        :to="link.nameAndUrl.url"
                                        :title="link.nameAndUrl.name"
                                        class="c-desktop-nav__link">
                                        <span class="c-desktop-nav__link-text">
                                            {{ link.nameAndUrl.name }}
                                        </span>
                                    </router-link>
                                </li>
                                <li
                                    v-else-if="
                                        navigationService.isProductsLink(link.type) &&
                                            link.nameAndUrl &&
                                            link.nameAndUrl.url
                                    "
                                    :key="ix"
                                    class="relative h-full px-10">
                                    <router-link
                                        :to="link.nameAndUrl.url"
                                        :title="link.nameAndUrl.name"
                                        class="c-desktop-nav__link"
                                        event
                                        @click.native.prevent="productsSelected()">
                                        <span class="c-desktop-nav__link-text">
                                            {{ link.nameAndUrl.name }}
                                        </span>
                                    </router-link>
                                </li>
                                <li
                                    v-else-if="
                                        navigationService.isRoomsLink(link.type) &&
                                            link.nameAndUrl &&
                                            link.nameAndUrl.url
                                    "
                                    :key="ix"
                                    class="relative h-full px-10">
                                    <router-link
                                        :to="link.nameAndUrl.url"
                                        :title="link.nameAndUrl.name"
                                        class="c-desktop-nav__link"
                                        event
                                        @click.native.prevent="roomsSelected()">
                                        <span class="c-desktop-nav__link-text">
                                            {{ link.nameAndUrl.name }}
                                        </span>
                                    </router-link>
                                </li>
                            </template>
                            <li
                                v-if="headerNavigation.seasonalLink && headerNavigation.seasonalLink.url"
                                class="relative h-full px-10">
                                <router-link
                                    :to="headerNavigation.seasonalLink.url"
                                    :title="headerNavigation.seasonalLink.name"
                                    class="c-desktop-nav__link">
                                    <span class="c-desktop-nav__link-text">
                                        {{ headerNavigation.seasonalLink.name }}
                                    </span>
                                </router-link>
                            </li>
                        </ul>
                        <nav class="hidden">
                            <!-- Links are only for SEO discovery (and accessibility) of stuff that will only be visible from Portal Overlay content -->
                            <router-link v-for="(seoLink, ix) in seoLinks" :key="ix" :to="seoLink.url">
                                {{ seoLink.name }}
                            </router-link>
                        </nav>
                    </div>
                </div>
                <div class="flex-shrink flex flex-1 justify-center">
                    <button
                        v-if="headerNavigation"
                        class="w-80% bg-white-300"
                        tabindex="-1"
                        @click="toggleSearch()"
                        @keydown="onSearchKeyDown">
                        <div class="pointer-events-none flex">
                            <div class="flex flex-shrink-0 pl-10">
                                <cIcon class="mr-5 flex-shrink-0 self-center bg-transparent" name="search" width="14"/>
                            </div>
                            <input
                                ref="searchInput"
                                v-prohibit-zoom
                                type="search"
                                :aria-label="searchLabel"
                                :placeholder="$translate('search.Overlay.InputPlaceholder')"
                                class="flex-shrink flex-grow c-search__input --full-placeholder-opacity block h-28 w-full min-w-0 bg-white-300 py-5 pl-5 pr-10 font-brandon text-12 font-light uppercase md:text-13 md:leading-18">
                        </div>
                    </button>
                </div>
                <div class="flex">
                    <ul class="c-desktop-nav__secondary">
                        <li
                            v-if="headerNavigation?.findStore && headerNavigation.findStore.url && headerNavigation.findStore.name"
                            class="c-desktop-nav__secondary-list-item mr-1">
                            <span class="c-desktop-nav__secondary-list-item-text">
                                <router-link
                                    v-prohibit-zoom
                                    class="text-current-color"
                                    :to="headerNavigation.findStore.url"
                                    :title="headerNavigation.findStore.name">
                                    <cIcon name="shop" class="text-brown-80" height="20"/>
                                </router-link>
                            </span>
                        </li>
                        <li
                            v-if="headerNavigation?.myPage && headerNavigation.myPage.url && headerNavigation.myPage.name"
                            class="c-desktop-nav__secondary-list-item">
                            <span class="c-desktop-nav__secondary-list-item-text">
                                <router-link
                                    v-prohibit-zoom
                                    class="text-current-color"
                                    :to="headerNavigation.myPage.url"
                                    :title="headerNavigation.myPage.name">
                                    <cIcon name="user" class="text-brown-80" height="20"/>
                                </router-link>
                            </span>
                        </li>
                        <li
                            v-if="headerNavigation?.favorites && headerNavigation.favorites.url && headerNavigation.favorites.name"
                            class="c-desktop-nav__secondary-list-item">
                            <span class="c-desktop-nav__secondary-list-item-text">
                                <router-link
                                    v-prohibit-zoom
                                    class="flex items-center text-current-color"
                                    :to="headerNavigation.favorites.url"
                                    :title="headerNavigation.favorites.name">
                                    <cIcon name="heart" class="text-brown-80" height="17.5"/>
                                </router-link>
                            </span>
                        </li>
                        <li v-if="hasCheckout" class="c-desktop-nav__secondary-list-item">
                            <span class="c-desktop-nav__secondary-list-item-text -ml-1">
                                <button
                                    v-prohibit-zoom
                                    :aria-label="labelToggleBasket"
                                    :title="headerNavigation?.basket.name"
                                    @click="toggleBasket">
                                    <span class="hidden">{{
                                        $translate(basketActive ? 'checkout.Basket.HideBasket' : 'checkout.Basket.ShowBasket')
                                    }}</span>
                                    <MiniBasket :animate="true" class="ml-5"/>
                                </button>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, defineEmits, defineProps } from 'vue';

import serverContext from '@/core/serverContext.service';
import basketStore from '@/store/basket.store';
import translateFilter from '@/core/translation/translate.filter';
import navigationService from './navigation.service';
import MiniBasket from '@/project/checkout/MiniBasket.vue';
import useHeaderNavigation from '@/project/layout/page-header/useHeaderNavigation';

defineProps<{ searchLabel?: string }>();
const emit = defineEmits(['productsSelected', 'roomsSelected', 'toggleSearch', 'toggleBasket']);

const { headerNavigation } = useHeaderNavigation();

const hasCheckout = computed(() => {
    return serverContext.checkoutContext.hasCheckout || false;
});

const basketActive = computed(() => {
    return basketStore.basketActive;
});

const basketCount = computed((): number | null => {
    if (hasCheckout.value && basketStore.basket && basketStore.itemCount !== null) {
        return basketStore.itemCount;
    }
    return null;
});

const productsSelected = () => {
    emit('productsSelected');
};

const roomsSelected = () => {
    emit('roomsSelected');
};

const toggleSearch = () => {
    emit('toggleSearch');
};

const toggleBasket = () => {
    emit('toggleBasket');
};

const seoLinks = computed(() => {
    return [
        ...(navigationService.getProductsLink()?.subNavigationLinks.map((l) => l.nameAndUrl) ?? []),
        ...(navigationService.getRoomsLink()?.subNavigationLinks.map((l) => l.nameAndUrl) ?? [])
    ];
});

const labelToggleBasket = computed((): string => {
    return `${translateFilter(basketActive.value ? 'checkout.Basket.HideBasket' : 'checkout.Basket.ShowBasket')} (${
        basketCount.value || 0
    })`;
});

const onSearchKeyDown = (e) => {
    if (e.key !== 'Tab' && e.key !== 'Shift') {
        toggleSearch();
    }
};
</script>
