import { ref } from 'vue';
import { HeaderNavigationModel } from '@/types/serverContract';
import Api from '@/project/http/api';

const headerNavigation = ref<HeaderNavigationModel | null>(null);

export const useHeaderNavigation = () => {
    async function setNavigation() {
        headerNavigation.value = await Api.navigation.get();
        window.vertica.headerNavigation = headerNavigation.value;
    };

    return {
        headerNavigation,
        setNavigation
    };
};

export default useHeaderNavigation;
