<template>
    <div class="flex min-h-full">
        <div class="relative z-5 flex w-full flex-col bg-white-100">
            <BasketHeader @closeClick="closeBasket"/>
            <div
                class="flex flex-1 flex-col content-start transition-transform duration-300"
                :class="{ 'min-h-screen-1/2': empty }">
                <div
                    class="flex-grow mx-auto flex w-full flex-col items-center bg-white-100 px-16 md:px-40"
                    :class="{ 'pb-40 md:pb-88': isCheckout }">
                    <div
                        v-if="isCheckout && itemCount < 2"
                        class="my-16 flex w-full items-center gap-12 bg-sand-30 px-16 py-25 md:py-16">
                        <cIcon class="h-24 w-24" name="info-frame"/>
                        <div class="font-gibson text-14 font-light leading-18">
                            {{ $translate('checkout.Basket.CloseInfo') }}
                        </div>
                    </div>
                    <BasketFreeShippingBanner class="my-16"/>
                    <transition
                        mode="out-in"
                        leave-active-class="animated fadeOut u-anim-dur-300"
                        enter-active-class="animated fadeIn u-anim-dur-300">
                        <div v-if="empty" key="init" class="mb-230 mt-128 text-center md:mb-440 md:mt-210">
                            <BasketModifiedByServerNotification/>
                            <ResponsiveIcon image-url="/images/empty-cart.png" :width="266" :height="204"/>
                            <h3 class="font-script my-5 text-32 leading-sm tracking-normal md:text-42">
                                {{ $translate('checkout.Basket.Empty.Text') }}
                            </h3>
                        </div>
                        <div v-else key="result" class="c-basket__result flex w-full flex-col justify-start">
                            <BasketModifiedByServerNotification/>
                            <LineItem
                                v-for="line in basketLinesWithNewProduct"
                                :key="line.sku"
                                :line="line"
                                :inventory="getLineInventory(line)"
                                :allow-edit="true"
                                class="border-b flex border-sand-40"/>
                        </div>
                    </transition>
                    <div v-if="!empty" class="my-30 md:my-54">
                        <CheckoutPaymentMethods/>
                    </div>
                </div>
                <BasketRecommendations v-if="!isCheckout"/>
                <div v-if="!isCheckout" class="w-full bg-sand-20 px-12 pb-40 md:px-40 md:pb-88">
                    <FAQ/>
                </div>
            </div>
            <BasketFooter v-if="!empty" @closeBasket="closeBasket"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, defineEmits, onMounted, ref, watch } from 'vue';
import ResponsiveIcon from '@/core/responsive/image/ResponsiveIcon.vue';
import serverContext from '@/core/serverContext.service';
import { router } from '@/core/spa/router';
import BasketFooter from '@/project/checkout/BasketFooter.vue';
import BasketFreeShippingBanner from '@/project/checkout/BasketFreeShippingBanner.vue';
import BasketHeader from '@/project/checkout/BasketHeader.vue';
import BasketModifiedByServerNotification from '@/project/checkout/BasketModifiedByServerNotification.vue';
import BasketRecommendations from '@/project/checkout/BasketRecommendations.vue';
import { CheckoutPageStepIndexes } from '@/project/checkout/checkoutPageStepIndexes';
import CheckoutPaymentMethods from '@/project/checkout/CheckoutPaymentMethods.vue';
import FAQ from '@/project/checkout/FAQ.vue';
import LineItem from '@/project/checkout/LineItem.vue';
import Api, { BasketLineWithNewProduct } from '@/project/http/api';
import googleTagManagerUtils, { tagManagerBasketProduct } from '@/project/tracking/googleTagManager.utils';
import mParticleUtils from '@/project/tracking/mParticle.utils';
import trackingUtils from '@/project/tracking/tracking.utils';
import basketStore from '@/store/basket.store';
import productsStore from '@/store/products/products.store';
import { Basket, v4 } from '@/types/serverContract';

const productInventory = ref<v4.Products.Inventory[] | null>(null);

const emit = defineEmits(['clearShow']);

onMounted(() => {
    trackingUtils.navigation.trackCheckoutFlowNavigation('basket');
    getBasketForTracking();
});

const basket = computed((): Basket | null => basketStore.basket);
const itemCount = computed((): number => basketStore.itemCount || 0);
const empty = computed((): boolean => itemCount.value === 0);
const checkoutUrl = computed((): string => serverContext.checkoutContext.checkoutUrl);
const currentRoute = computed(() => router.currentRoute.path);
const isCheckout = computed(() => checkoutUrl.value === currentRoute.value);

const getBasketForTracking = async() => {
    await Api.basket
        .get()
        .then((basket) => {
            if (!(basket && basket.lines)) return;
            const products = basket.lines.map((line) => (line as any as BasketLineWithNewProduct).product);
            getInventory(products);
            const mappedProducts: tagManagerBasketProduct[] = [];
            basket.lines.map((line) => {
                const lineStyle = googleTagManagerUtils.basket.formatLineItemProductToTrackingStyle(line as any as BasketLineWithNewProduct);
                if (lineStyle) {
                    mappedProducts.push(lineStyle);
                }
            });
            // First time doing checkout event, catch the basket to feed products.
            mParticleUtils.catalog.trackCheckoutStep(CheckoutPageStepIndexes.Basket);
            googleTagManagerUtils.basket.trackCheckoutStep(CheckoutPageStepIndexes.Basket, mappedProducts);
        })
        .catch(() => {
            // avoid logging with an empty catch...
        });
};

const getInventory = (products: v4.Products.ProductSimple[]) => {
    productInventory.value = products.flatMap(p => p.variants).map(v => v.inventory);
};

const basketLinesWithNewProduct = computed(() => {
    return basket.value?.lines as any as BasketLineWithNewProduct[];
});

const getLineInventory = (lineItem: BasketLineWithNewProduct): v4.Products.Inventory | null => {
    let inventory = productInventory.value && productInventory.value.find((i) => i.sku === lineItem.sku);
    if (!inventory) {
        const inventories = inventoryByProductId(lineItem.product);
        inventory = inventories && inventories.find((i) => i.sku === lineItem.sku);
    }
    return inventory || null;
};

const closeBasket = () => {
    emit('clearShow');
};

const inventoryByProductId = (product: v4.Products.ProductSimple) => {
    return productsStore.getInventory(product.id);
};

watch([isCheckout, itemCount], ([isCheckout, count], [prevIsCheckout, prevCount]) => {
    if (!isCheckout) return;
    if (count === 0 && prevCount === 1) {
        setTimeout(() => {
            router.push({
                path: basketStore.returnUrl ?? '/'
            });
        }, 1500);
    }
});
</script>
