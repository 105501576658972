<template>
    <div ref="block" :class="[sectionClass]" :style="{ height: sectionHeight + 'px' }">
        <div
            ref="parallax"
            :class="[parallax ? parallaxClass : '', fixed ? fixedClass : '', containerClass]">
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

@Component({
    name: 'Parallax'
})
export default class Parallax extends Vue {
    @Prop({
        default: false,
        type: Boolean
    })
    debug!: boolean;

    @Prop({
        default: true,
        type: Boolean
    })
    parallax!: boolean;

    @Prop({
        default: 0.15,
        type: Number
    })
    speedFactor!: number;

    @Prop({
        default: false,
        type: Boolean
    })
    fixed!: boolean;

    @Prop({
        default: '(min-width: 968px)',
        type: String
    })
    breakpoint!:string;

    @Prop({
        default: 70,
        type: Number,
        required: false
    })
    sectionHeight!: number;

    @Prop({
        type: String,
        default: 'Masthead'
    })
    sectionClass!: string;

    @Prop({
        type: String,
        default: 'Masthead__image'
    })
    containerClass!:string;

    @Prop({
        type: String,
        default: 'is-parallax'
    })
    parallaxClass!: string;

    @Prop({
        type: String,
        default: 'is-fixed'
    })
    fixedClass!:string;

    @Prop({
        type: String,
        default: 'up'
    })
    direction!: string;

    prevScrollTop: number = 0;
    el: any = null;
    mediaQuery: any = null;
    $refs!: {
        block: HTMLInputElement,
        parallax: HTMLInputElement
    };

    get directionValue(): 1 | -1 {
        return this.direction === 'down' ? +1 : -1;
    }

    mounted() {
        if (this.parallax && !this.fixed) {
            this.el = this.$refs.parallax;
            this.init();
        }
    }

    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollHandler, false);
    }

    animateElement() {
        const windowScrollTop = window.scrollY || document.documentElement.scrollTop;
        const elmHeight = this.$refs.block.offsetHeight;
        const elmOffset = this.$refs.block.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop);

        if (windowScrollTop === this.prevScrollTop) {
            // No scrolling has occured
            return;
        } else {
            this.prevScrollTop = windowScrollTop;
        }
        const calc = (elmOffset - windowScrollTop) / elmHeight * 100 * this.speedFactor;
        this.$refs.parallax.style.transform = `translate3d(0, ${Math.round(calc) * this.directionValue}px ,0)`;
    }

    scrollHandler() {
        window.requestAnimationFrame(() => {
            if (this.isInView(this.$refs.block)) {
                this.animateElement();
            }
        });
    }

    isInView(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.bottom >= 0 &&
          rect.top <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    setupListener() {
        if (this.mediaQuery.matches) {
            window.addEventListener('scroll', this.scrollHandler, false);
            this.scrollHandler();
            setTimeout(this.scrollHandler, 50); // allow screen to update at init
            if (import.meta.env.MODE !== 'production') {
                // When in development mode, allow more delays to update screen
                setTimeout(this.scrollHandler, 200); // allow screen to update at init
                setTimeout(this.scrollHandler, 300); // allow screen to update at init
            }
        } else {
            window.removeEventListener('scroll', this.scrollHandler, false);
        }
    }

    init() {
        this.mediaQuery = window.matchMedia(this.breakpoint);
        if (this.mediaQuery) {
            this.mediaQuery.addListener(this.setupListener);
            this.setupListener();
        }
    }
}
</script>

<style lang="less" scoped>
.Masthead {
    position: relative;
    min-height: 35rem;
    scroll-behavior: smooth;
    overflow: hidden;
    z-index: -1;
    width: 100%;
    overflow: hidden;
    height: 120%;

    > img {
        height: 100%;
        max-width: none;
        width: 100%;
        object-fit: cover;
        object-position: top;
    }
    .is-parallax {
        left: 0;
        position: absolute;
        will-change: transform;
        right: 0;
        top: 0;

        > img {
            height: 100%;
            max-width: none;
            width: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    .is-fixed {
        position: fixed;
        will-change: transform;

        > img {
            height: 100vh;
            max-width: none;
        }
    }
}

@media min-md {
    .Masthead {
        min-height: 100vh;
    }
}
</style>
