<template>
    <div class="w-full">
        <div class="flex flex-wrap justify-between font-gibson-light text-12 leading-14 md:text-14 md:leading-16 md:pb-4">
            <div>
                {{ getPriceLabel }}
            </div>
            <div class="text-right">
                {{ getPriceFormatted }}
            </div>
        </div>
        <div class="flex flex-wrap justify-between font-gibson-light text-12 leading-14 md:text-14 md:leading-16 pb-4">
            <div>
                {{ $translate('checkout.Basket.ShippingPrice') }}
            </div>
            <div class="text-right">
                {{ getShippingPriceFormatted }}
            </div>
        </div>
        <div class="flex flex-wrap justify-between font-gibson-regular text-14 leading-16 md:text-16">
            <div>
                {{ $translate('checkout.Basket.TotalInclShipping') }}
            </div>
            <div class="text-right">
                {{ getTotalInclShippingFormatted }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import translateFilter from '@/core/translation/translate.filter';
import currencyFilter from '@/project/shared/currency/currency.filter';

@Component
export default class Totals extends Vue {
    @Prop({ type: Number, required: true })
    public numProducts!: number;

    @Prop({ type: Number, required: true })
    price!: number; // Price for the products, incl vat, excl. shipping

    @Prop({ required: true, validator: (p) => { return (typeof p === 'number') || (p === null); } }) // null => "Calculated later"
    shippingPrice!: number | null;

    @Prop({ required: true, validator: (p) => { return (typeof p === 'number') || (p === null); } }) // null => "Calculated later"
    totalInclShipping!: number | null;

    @Prop({ type: Boolean, required: true })
    exactShippingPrice!: boolean; // Whether the shipping price is exact or an estimate

    get getPriceLabel(): string {
        const label:string = 'checkout.Basket.' + (this.numProducts === 1 ? 'OneProduct' : 'XProducts');
        return translateFilter(label, this.numProducts.toString());
    }

    get getPriceFormatted(): string {
        return currencyFilter(this.price, true);
    }

    get getShippingPriceFormatted(): string {
        const price: number | null = this.shippingPrice;
        if (price == null) {
            // ie "Calculated later"
            return translateFilter('checkout.Basket.ShippingPriceDeterminedLater');
        } else if (price === 0) {
            // ie "Free"
            return translateFilter('checkout.Basket.ShippingPrice.FromLimitReached');
        } else {
            if (this.exactShippingPrice) {
                return currencyFilter(price, true);
            } else {
            // ie "From 29.50 USD"
                return translateFilter('checkout.Basket.ShippingPrice.FromLimitNotReached', currencyFilter(price, true));
            }
        }
    }

    get getTotalInclShippingFormatted(): string {
        const total:number | null = this.totalInclShipping;
        if (total == null) {
            // ie "Calculated later"
            return translateFilter('checkout.Basket.ShippingPriceDeterminedLater');
        } else {
            return currencyFilter(total, true);
        }
    }
}
</script>
