<template>
    <div class="page-blind" :class="classNames" @click="deactivatePageBlind()"/>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class PageBlind extends Vue {
    @Prop() clickHandler!: Function;
    @Prop({
        type: String,
        default: 'absolute inset-0 z-1'
    }) classNames?: string;

    deactivatePageBlind() {
        if (this.clickHandler) {
            this.clickHandler();
        }
    }
}
</script>

<style lang="less" scoped>
.page-blind {
    background-color: color-mod(theme('colors.black') a(0.5));
}
</style>
