<template>
    <div class="sticky inset-x-0 top-0 z-mobile-menu flex w-full items-center bg-white-100 md:ml-auto">
        <div class="w-full md:h-full">
            <OverlayHeader :is-basket="isCheckout ? false : true" @close="closeBasket">
                <MiniBasket v-if="!isCheckout" class="mr-24 md:mr-48"/>
                {{ title }}
            </OverlayHeader>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, defineEmits } from 'vue';
import { router } from '@/core/spa/router';
import translateFilter from '@/core/translation/translate.filter';
import MiniBasket from '@/project/checkout/MiniBasket.vue';
import OverlayHeader from '@/project/shared/overlay/OverlayHeader.vue';
import serverContext from '@/core/serverContext.service';

const emit = defineEmits(['closeClick']);

const checkoutUrl = computed(() => serverContext.checkoutContext.checkoutUrl);
const currentRoute = computed(() => router.currentRoute.path);
const isCheckout = computed(() => checkoutUrl.value === currentRoute.value);

const closeBasket = () => {
    emit('closeClick');
};

const title = computed(() => {
    if (isCheckout.value) {
        return translateFilter('checkout.Basket.Heading.Checkout');
    }
    return translateFilter('checkout.Basket.Heading');
});
</script>
