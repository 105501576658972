<template>
    <PortalOverlay
        :close-on-route-change="true"
        :close-outside="false"
        :disable-body-scroll="true"
        :show-blind="true"
        :show-close-button="false"
        :show.sync="open"
        portal="password-reset-overlay"
        side="fade"
        wrapper-class="flex bg-white-300">
        <div class="min-h-full w-full bg-white-100">
            <div class="fixed left-0 right-0 top-0 z-1 bg-white-100">
                <header class="o-container" role="banner">
                    <div class="py-15 md:py-20">
                        <div class="mx-auto w-full max-w-150">
                            <router-link
                                :to="marketUrl"
                                :title="$translate('search.Overlay.Close')"
                                class="mx-auto w-full max-w-150"
                                @click.native="close">
                                <c-icon class="w-full pt-4" name="logo-collapsed"/>
                            </router-link>
                        </div>
                    </div>
                </header>
            </div>
            <div class="mx-auto flex h-full w-full justify-center">
                <div class="h-full w-full pt-90 md:pt-100">
                    <div class="flex h-full flex-col">
                        <ForgotPasswordForm
                            ref="forgotPasswordFormComponent"
                            class="w-full px-20"
                            success-submit="checkout.Receipt.Success.ResetPassword.Back"
                            :success-submit-click-handler="close"
                            :is-in-focus="open"
                            @success="close"
                            @resetPassword="showBackToLogin = false"/>
                        <div v-if="showBackToLogin" class="flex-grow bg-sand-20 px-20 py-24">
                            <div class="mx-auto mb-5 flex w-full max-w-290">
                                <button
                                    class="c-btn c-btn--primary-outlined mx-auto h-40 w-full justify-center"
                                    type="button"
                                    @click="close">
                                    <span class="c-btn__text tracking-medium md:tracking-medium-heading">
                                        {{ $translate('checkout.Receipt.Success.ResetPassword.Back') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PortalOverlay>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import bus from '@/core/bus';
import serverContext from '@/core/serverContext.service';
import ForgotPasswordForm from '@/project/login/ForgotPasswordForm.vue';
import { OpenReceiptPasswordResetKey } from '@/project/config/constants';

@Component({
    components: { ForgotPasswordForm }
})
export default class ReceiptResetPasswordOverlay extends Vue {
    open: boolean = false;
    showBackToLogin = true;

    $refs!: {
        forgotPasswordFormComponent: ForgotPasswordForm;
    };

    created() {
        bus.on(OpenReceiptPasswordResetKey, this.openOverlay);
    }

    destroyed() {
        bus.off(OpenReceiptPasswordResetKey, this.openOverlay);
    }

    get marketUrl() {
        return serverContext.marketUrl;
    }

    openOverlay() {
        this.open = true;

        setTimeout(() => {
            this.$refs.forgotPasswordFormComponent.clearValidation();
        }, 100);
    }

    close() {
        this.open = false;
    }
}
</script>
