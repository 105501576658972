<template>
    <div>
        <PortalOverlay v-slot="{ scrollPosition }"
                       portal="specifications-overlay"
                       wrapper-class="specifications-overlay-wrap w-full md:max-w-480 ml-auto top-auto"
                       :show.sync="modalOpen"
                       :close-outside="false"
                       :show-close-button="false"
                       :disable-body-scroll="true"
                       :show-blind="true"
                       :close-on-route-change="true"
                       :side="side">
            <div v-if="modalOpen"
                 class="relative">
                <div class="sticky top-0 border-b bg-sand-10 border-sand-40 p-20 md:pt-32 md:pb-0">
                    <h3 v-if="data" class="font-medium text-center uppercase font-brandon text-14 mb-0 md:text-left md:pb-32">
                        {{ data.title }}
                    </h3>
                    <button class="absolute my-auto right-0 top-1/2 mr-20 -mt-10"
                            :aria-label="$translate('generic.Close')"
                            @click="close">
                        <cIcon class="self-center bg-transparent relative"
                               name="close"
                               width="16"
                               height="16"/>
                    </button>
                </div>
                <div class="py-32 px-20">
                    <div v-for="(entry, eIx) in data?.sections"
                         :key="eIx"
                         class="mb-32">
                        <h6 class="text-brown-80 px-16 font-brandon-medium mb-16 leading-16 uppercase text-12">
                            {{ entry.header }}
                        </h6>
                        <component :is="getComponent(entry)"
                                   :data="entry"/>
                    </div>
                </div>
                <transition mode="out-in"
                            enter-active-class="animated fadeIn u-anim-dur-200"
                            leave-active-class="animated fadeOut u-anim-dur-200">
                    <div v-if="!scrollPosition"
                         class="specifications-overlay-bottom-fade sticky bottom-0 left-0 right-0 text-sand-10 h-100"></div>
                </transition>
            </div>
        </PortalOverlay>
    </div>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, onUnmounted, ref, watch } from 'vue';
import bus from '@/core/bus';
import { CloseProductSpecificationsKey, OpenProductSpecificationsKey } from '@/project/config/constants';
import BreakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';
import { v4 } from '@/types/serverContract';
const SpecificationsTable = defineAsyncComponent(() => import('@/project/product/details/SpecificationsTable.vue'));
const SpecificationsText = defineAsyncComponent(() => import('@/project/product/details/SpecificationsText.vue'));
const SpecificationsTextList = defineAsyncComponent(() => import('@/project/product/details/SpecificationsTextList.vue'));

const data = ref<v4.Products.Information | null>(null);
const side = ref('bottom');
const modalOpen = ref(false);

const activeBreakpoint = computed(() => BreakpointsState.activeBreakpoint);

watch(activeBreakpoint, () => {
    const isMinMd = BreakpointsState.isBreakpointActive('min-md');
    side.value = isMinMd ? 'right' : 'bottom';
},
{ immediate: true });

const setData = (val: v4.Products.Information) => {
    data.value = val;
    modalOpen.value = true;
};

const getComponent = (entry) => {
    if (entry.type === v4.Products.DetailsType[0]) {
        return SpecificationsTable;
    } else if (entry.type === v4.Products.DetailsType[1]) {
        return SpecificationsTextList;
    } else if (entry.type === v4.Products.DetailsType[2]) {
        return SpecificationsText;
    }
};

const close = () => {
    modalOpen.value = false;
};

bus.on(OpenProductSpecificationsKey, setData);
bus.on(CloseProductSpecificationsKey, close);

onUnmounted(() => {
    bus.off(OpenProductSpecificationsKey, setData);
    bus.off(CloseProductSpecificationsKey, close);
});

</script>

<style lang="less">
.specifications-overlay-wrap {
    top: 5.6rem;
    height: calc(100dvh - 5.6rem);
    bottom: 0;
    @apply bg-white-100;
}

.specifications-overlay-bottom-fade {
    background: linear-gradient(180deg,hsla(0,0%,100%,0),currentColor 98%)
}

.variant-wrap {
    @apply relative z-3 min-h-full h-full my-auto px-20 w-full flex flex-col;
}

@screen md {
    .variant-wrap {
        margin-top: auto !important;
    }
}

@screen md {
    .specifications-overlay-wrap {
        top: 0;
        height: 100vh;
    }
}
</style>
