import { SpaPageRenderedEventKey } from '@/core/spa/router';
import bus from '@/core/bus';
import spaStore from '@/core/spa/store/spa.store';
import serverContext from '@/core/serverContext.service';
import { doDataLayerPush } from '@/project/tracking/googleTagManager.utils';
import loggingService from '@/core/logging.service';
enum certainlyWidgetStatusAction {
    'open' = 'open',
    'close' = 'close',
    'show' = 'show',
    'hide' = 'hide'
}
interface certainlyWidgetStatus {
    action: certainlyWidgetStatusAction;
    webchatKey: string;
}

declare global {
    interface Window {
        // eslint-disable-next-line camelcase
        certainly_settings: any;
    }
}

const timeoutWaitTime = 1000;
const maxTimesToTry = 10;
let timesTriedToInitChat = 0;

if (window.vertica.chat) {
    bus.on(SpaPageRenderedEventKey, () => {
        tryToLoadChat();
    });
}

function tryToLoadChat() {
    if (window.certainly) {
        handleChatbotInit();
    } else if (timesTriedToInitChat < maxTimesToTry) {
        timesTriedToInitChat++;
        setTimeout(() => {
            tryToLoadChat();
        }, timeoutWaitTime);
    }
}

function handleChatbotInit() {
    if (!spaStore.chatbotActiveLoaded) {
        if (!spaStore.chatbotActive) return;
        if (!window.embedded_svc) {
            const s = document.createElement('script');
            s.setAttribute('src', `${window.vertica.chat.salesforce.baseUrl}/embeddedservice/5.0/esw.min.js`);
            s.onload = function() {
                initESW(null);
            };
            document.body.appendChild(s);
        } else {
            initESW('https://service.force.com');
        }
        certainlyInit();
    } else {
        const action: certainlyWidgetStatusAction = spaStore.chatbotActive ? certainlyWidgetStatusAction.show : certainlyWidgetStatusAction.hide;
        // @ts-ignore
        toggleChatbotVisibility(action);
    }
}

function initESW(gslbBaseURL) {
    window.embedded_svc.settings.displayHelpButton = true; /* Or false */
    window.embedded_svc.settings.language = serverContext.culture; /* For example, enter 'en' or 'en-US' */

    window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    window.embedded_svc.settings.entryFeature = 'LiveAgent';

    window.embedded_svc.init(
        window.vertica.chat.salesforce.baseUrl,
        `${window.vertica.chat.salesforce.agentUrl}/liveAgentSetupFlow`,
        gslbBaseURL,
        window.vertica.chat.salesforce.embedID,
        window.vertica.chat.salesforce.embedChat,
        window.vertica.chat.salesforce.embedSettings
    );
};

function toggleChatbotVisibility(newAction: certainlyWidgetStatusAction, callback = () => {}) {
    // @ts-ignore
    window.certainly.widgetStatus({ action: newAction, webchatKey: window.vertica.chat.webchatKey } as certainlyWidgetStatus, callback);
}
function certainlyInit() {
    spaStore.chatbotActiveLoaded = true;
    window.certainly_settings = {
        id: window.vertica.chat.certainlyConfig.botId,
        mode: 'clear_past_conversations',
        webchatKey: window.vertica.chat.webchatKey,
        zIndex: 15,
        background: '#fffef8',
        buttonWidth: 60,
        buttonHeight: 60,
        botxoUnbranded: 1,
        buttonLogoOpen: '/images/chatbot/chat-open.svg',
        buttonLogoClose: '/images/chatbot/chat-close.svg',
        inputType: 'bottom',
        chatMessage: {
            bot: {
                color: '#4F4139',
                buttons: {
                    color: '#4F4139',
                    backgroundColor: '#F9F7F0'
                },
                linkColor: '#BFACA8',
                backgroundColor: '#FFFEF8',
                border: '1px solid #DEDEDE',
                card: {
                    backgroundColor: '#F9F7F0',
                    border: '1px solid #F1EDE3',
                    boxShadow: 'none',
                    text: {
                        color: '#4F4139'
                    }
                }
            },
            user: {
                color: '#FFFEF8',
                linkColor: '#80837B',
                backgroundColor: '#4F4139'
            },
            header: {
                boxShadow: 'none',
                backgroundColor: '#F9F7F0'
            }
        }
    };
    // @ts-ignore
    window.initCertainlyWidget(window.certainly_settings, () => {
        const s = document.createElement('script');
        s.setAttribute('src', 'https://scripts.certainly.io/extensions/salesforce/salesforce.js');
        document.body.appendChild(s);
        // @ts-ignore
        toggleChatbotVisibility(certainlyWidgetStatusAction.close);

        if (!window.certainly) {
            loggingService.error('Certainly script not loaded as expected');
            doDataLayerPush({
                event: 'GAEvent',
                eventCategory: 'Chatbot',
                eventAction: 'botError',
                eventLabel: undefined,
                eventValue: undefined
            });
        }
    });
}
