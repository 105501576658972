import { computed, reactive, watch } from 'vue';
import serverContext from '@/core/serverContext.service';
import basketStore from '@/store/basket.store';
import { Viewport } from '@/core/responsive/viewport/viewportEvents.service';

const hasCheckout = computed(() => {
    return serverContext.checkoutContext.hasCheckout || false;
});

const basketCount = computed(() => {
    if (hasCheckout.value && basketStore.basket && basketStore.itemCount !== null) {
        return basketStore.itemCount;
    }
    return null;
});
watch(basketCount, (newBasketCount) => {
    if (!newBasketCount || navigationbarStore.hidden === false || navigationbarStore.animateIn === true) {
        return;
    }
    navigationbarStore.clearTimer();
    navigationbarStore.animateIn = true;
    navigationbarStore.timer = setTimeout(() => {
        // TODO figure out if this should be called from MiniBasket on animation complete instead
        navigationbarStore.animateIn = false;
    }, 4000);
});

interface NavigationbarStore {
    timer: number | null;
    hidden: boolean;
    animateIn: boolean;
    showAtNextCategory: boolean;
    scrollY: number;
    lastScrollY: number;
    lastTimestamp: number;
    clearTimer: () => void;
    handleScroll: (viewport: Viewport, timestamp: number) => void;
}

export const navigationbarStore = reactive<NavigationbarStore>({
    timer: null,
    hidden: false,
    animateIn: false,
    showAtNextCategory: false,
    scrollY: 0,
    lastScrollY: 0,
    lastTimestamp: 0,
    clearTimer: () => {
        if (navigationbarStore.timer) {
            clearTimeout(navigationbarStore.timer);
        }
    },
    handleScroll: (viewport: Viewport, timestamp: number) => {
        const viewportScrollY = Math.floor(viewport.scrollY);

        if (viewportScrollY === navigationbarStore.scrollY) {
            // only continue if scroll position has changed
            return;
        }

        navigationbarStore.scrollY = viewportScrollY;

        if (navigationbarStore.scrollY > navigationbarStore.lastScrollY && navigationbarStore.scrollY > 20) {
            // scrolling down

            if (navigationbarStore.showAtNextCategory) {
                // if `showAtNextCategory`: always show navbar (also when scrolled down)
                navigationbarStore.animateIn = true;
            } else {
                // else: default behavior hide navbar when scrolling down
                navigationbarStore.animateIn = false;
            }
        } else {
            // scrolling up
            const velocity = Math.abs(
                (navigationbarStore.scrollY - navigationbarStore.lastScrollY) /
                    (timestamp - navigationbarStore.lastTimestamp)
            );
            if (navigationbarStore.scrollY < 20 || velocity > 0.8) {
                navigationbarStore.animateIn = true;

                // clear any existing timer
                navigationbarStore.clearTimer();
            }
        }

        navigationbarStore.lastScrollY = viewportScrollY;
        navigationbarStore.lastTimestamp = timestamp;
    }
});
