export default class Deferred<T> {
    private _resolve: (value: T | PromiseLike<T>) => void = () => {};
    private _reject: (value: T | PromiseLike<T>) => void = () => {};

    private _promise: Promise<T> = new Promise<T>((resolve, reject) => {
        this._reject = reject;
        this._resolve = resolve;
    })

    public get promise(): Promise<T> {
        return this._promise;
    }

    public resolve(value: T): void {
        this._resolve(value);
    }

    public reject(value?: any): void {
        this._reject(value);
    }
}
