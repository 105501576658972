<template>
    <div class="md:flex md:flex-wrap">
        <div class="pb-32 md:pb-0 md:w-1/2 md:pr-10 lg:pr-12">
            <ProductProvider v-if="leftProductSearchByIdOrSku"
                             v-slot="{ product }"
                             :product-id="leftProductSearchByIdOrSku.id"
                             :product-sku="leftProductSearchByIdOrSku.sku">
                <div :class="{'placeholder': !product}">
                    <BlockLargeProductTeaserLargeProduct :product="product"
                                                         :text-color="content.textColor"
                                                         :width-on-screen="widthOnScreen"
                                                         :content="content.leftPart.content"
                                                         :image-aspect-ratio="imageAspectRatio"
                                                         :variant="leftProductSearchByIdOrSku.sku"
                                                         :tracking-index="firstImageTrackingIndex"/>
                </div>
            </ProductProvider>
            <BlockLargeProductTeaserLargeProduct v-else
                                                 :width-on-screen="widthOnScreen"
                                                 :text-color="content.textColor"
                                                 :content="content.leftPart.content"
                                                 :image-aspect-ratio="imageAspectRatio"
                                                 :tracking-index="firstImageTrackingIndex"/>
        </div>
        <div class="md:w-1/2 md:pl-10 lg:pl-12">
            <ProductProvider v-if="rightProductSearchByIdOrSku"
                             v-slot="{ product }"
                             :product-id="rightProductSearchByIdOrSku.id"
                             :product-sku="rightProductSearchByIdOrSku.sku">
                <div :class="{'placeholder': !product}">
                    <BlockLargeProductTeaserLargeProduct :product="product"
                                                         :text-color="content.textColor"
                                                         :content="content.rightPart.content"
                                                         :width-on-screen="widthOnScreen"
                                                         :image-aspect-ratio="imageAspectRatio"
                                                         :variant="rightProductSearchByIdOrSku.sku"
                                                         :tracking-index="secondImageTrackingIndex"/>
                </div>
            </ProductProvider>
            <BlockLargeProductTeaserLargeProduct v-else
                                                 :text-color="content.textColor"
                                                 :content="content.rightPart.content"
                                                 :width-on-screen="widthOnScreen"
                                                 :image-aspect-ratio="imageAspectRatio"
                                                 :tracking-index="secondImageTrackingIndex"/>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue';
import { CampaignProductFocusBlockModel } from '@/types/serverContract';
import BlockLargeProductTeaserLargeProduct from '@/project/spa/blocks/largeProductTeaser/BlockLargeProductTeaserLargeProduct.vue';

const props = defineProps<{
  content: CampaignProductFocusBlockModel;
}>();

const firstImageTrackingIndex = 0;
const secondImageTrackingIndex = 1;

const leftProductSearchByIdOrSku = computed<{ id:string, sku: string } | null>(() => {
    if (props.content.leftPart.content.productId) {
        return { id: props.content.leftPart.content.productId, sku: props.content.leftPart.content.sku };
    }
    return null;
});

const rightProductSearchByIdOrSku = computed<{ id:string, sku: string } | null>(() => {
    if (props.content.rightPart.content.productId) {
        return { id: props.content.rightPart.content.productId, sku: props.content.rightPart.content.sku };
    }
    return null;
});

const widthOnScreen = !!props.content.leftPart && !!props.content.rightPart ? { 'md': 50, xs: 100 } : 100;

const imageAspectRatio = computed(() => {
    return {
        'max-ls': 350 / 512,
        'min-md': 648 / 677
    };
});
</script>

<style scoped>
.placeholder {
    aspect-ratio: 350 / 512;
    @apply bg-sand-20 w-full h-full;
}

@screen md {
    .placeholder {
        aspect-ratio: 648 / 677;
    }
}
</style>