<template>
    <div>
        <Breadcrumb justification-class="md:justify-start" class="md:mb-24"/>
        <breakpoints>
            <div slot="max-ls">
                <article>
                    <ProductDetailsToasts/>
                    <ProductDetailsHeroCarousel
                        :details="content.details"
                        :selected-variant="selectedVariant"
                        :has-video="hasVideo"/>
                    <div class="o-container">
                        <ProductDetailsInformation :details="content.details" :selected-variant="selectedVariant"/>
                    </div>
                </article>
            </div>
            <div slot="min-ls">
                <div class="o-container">
                    <article class="wrap">
                        <div class="w-600 md:mb-40 lg:w-922">
                            <ProductDetailsToasts/>
                            <ProductDetailsMediaContent
                                :details="content.details"
                                :selected-variant="selectedVariant"/>
                        </div>
                        <ProductDetailsInformation :details="content.details" :selected-variant="selectedVariant"/>
                    </article>
                </div>
            </div>
        </breakpoints>
        <ProductDetailsRecommendations
            :status="recommendationsStatus"
            :recommendations="recommendations"
            @inView="onRecommendationInView"/>
        <ProductDetailsProjects v-if="hasProjects" :projects="content.details.projects || []"/>
        <ProductDetailsRecommendations
            :status="recommendationsStatus"
            :recommendations="recentlyViewed"
            @inView="onRecommendationInView"/>
        <template v-if="jsonldobj">
            <script type="application/ld+json" v-html="jsonldobj"></script>
        </template>
    </div>
</template>
<script setup lang="ts">
import { computed, defineProps, ref, watch } from 'vue';
import Api from '@/project/http/api';
import spaStore from '@/core/spa/store/spa.store';
import mParticleUtils from '@/project/tracking/mParticle.utils';
import googleTagManagerUtils, { doDataLayerEcomPush } from '@/project/tracking/googleTagManager.utils';
import { useRoute } from 'vue-router/composables';
import usePdpJsonld from '@/project/product/details/usePDPJsonld';
import { useRecommendations, recommendationsId } from '@/project/product/details/useRecommendations';
import Breadcrumb from '@/project/shared/navigation/Breadcrumb.vue';
import ProductDetailsToasts from '@/project/product/details/ProductDetailsToasts.vue';
import ProductDetailsHeroCarousel from '@/project/product/details/ProductDetailsHeroCarousel.vue';
import ProductDetailsMediaContent from '@/project/product/details/ProductDetailsMediaContent.vue';
import ProductDetailsInformation from '@/project/product/details/ProductDetailsInformation.vue';
import ProductDetailsRecommendations from '@/project/product/details/ProductDetailsRecommendations.vue';
import ProductDetailsProjects from '@/project/product/details/ProductDetailsProjects.vue';
import { MediaType, ProductDetailsContentModel, v4 } from '@/types/serverContract';
import domService from '@/core/dom.service';
import { SpaPageRenderedEventKey } from '@/core/spa/router';
import bus from '@/core/bus';

const props = defineProps<{
    content: ProductDetailsContentModel;
}>();

const route = useRoute();
const hasTrackedFirstView = ref(false);
const selectedVariant = computed(() => {
    const variant = route.query?.variant;
    return props.content.details.variants.find((v) => v.sku === variant) || props.content.details.variants[0];
});

watch(
    selectedVariant,
    (selectedVariant, oldVariant) => {
        if (selectedVariant.sku === oldVariant?.sku) {
            return;
        }
        const payload = {
            detail: {
                products: [
                    googleTagManagerUtils.catalog.formatProductDataToGoogleTrackingStyle({
                        id: props.content.details.id,
                        name: props.content.details.name,
                        price: selectedVariant.pricing.unit.amount,
                        inventory: selectedVariant.inventory?.state,
                        variantSku: selectedVariant.sku,
                        variantColorName: selectedVariant.color?.name,
                        brandName: props.content.details.brand?.name,
                        shortDescriptionLength: props.content.details.tracking.shortDescriptionLength,
                        description: props.content.details.description,
                        labelling: props.content.details.tracking.labelling,
                        category: props.content.details.tracking.categoryIds[0],
                        numberOfImages: selectedVariant.sortedMedia.filter((x) => x.type === MediaType.Image).length
                    })
                ]
            }
        };
        if (spaStore.isInitialPage && hasTrackedFirstView.value === false) {
            doDataLayerEcomPush('originalProductDetail', payload);
        } else {
            doDataLayerEcomPush('productDetail', payload);
        }
        hasTrackedFirstView.value = true;

        mParticleUtils.catalog.trackProductDetailsView((props.content.details as unknown as v4.Products.ProductSimple), (selectedVariant as unknown as v4.Products.VariantSimple));
        domService.setOpenGraphProduct(props.content.details, selectedVariant);
    },
    { immediate: true, deep: true }
);

const hasVideo = computed(() => {
    return selectedVariant.value.sortedMedia.some((m) => m.type === MediaType.Video);
});

const hasProjects = computed(() => {
    const { projects } = props.content.details;
    return projects && projects.length > 0;
});

const { jsonldobj } = usePdpJsonld(props.content.details, selectedVariant.value);

watch(
    () => props.content.details.id,
    (id) => {
        recommendationsId.value = id;
    },
    { immediate: true }
);

// Watch route and send tracking data when changing variant, so that Relewise delivers correct recommendation
watch([() => route.path, () => route.query], ([path, query], [oldPath, oldQuery]) => {
    if (path === oldPath && (query as any)?.variant !== (oldQuery as any)?.variant) {
        Api.recommendations.trackVariantImpression(props.content.details.id, selectedVariant.value.sku);
    }
});

// Make sure we have data on new page, before sending data
bus.on(SpaPageRenderedEventKey, () => {
    Api.recommendations.trackVariantImpression(props.content.details.id, selectedVariant.value.sku);
});

const {
    recommendations,
    recentlyViewed,
    status: recommendationsStatus,
    onRecommendationInView
} = useRecommendations(recommendationsId);

</script>

<style lang="less" scoped>
.wrap {
    gap: 3.2rem;
    @apply flex align-top;
}
</style>
