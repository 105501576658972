<template>
    <PortalOverlay portal="ecocharge-overlay"
                   :show.sync="modalOpen"
                   wrapper-class="max-w-390 ml-auto"
                   :disable-body-scroll="false"
                   :show-close-button="false"
                   :close-outside="false"
                   :show-blind="true"
                   :close-on-route-change="true"
                   :side="side">
        <div class="bg-white-100 relative z-3 min-h-screen my-auto px-20 w-full">
            <div class="h-56 border-b border-sand-40 flex">
                <div class="flex-grow leading-13 text-center md:text-left my-auto -mr-20 font-brandon text-13 font-medium uppercase">
                    {{ $translate('product.EcoParticipation.Description.Ecologic.Title') }}
                </div>
                <button class="my-auto ml-auto md:block"
                        :aria-label="$translate('generic.Close')"
                        @click="modalOpen = false">
                    <cIcon class="self-center bg-transparent relative"
                           name="close"
                           width="16"
                           height="16"/>
                </button>
            </div>
            <div>
                <div v-delegate-html-links class="wysiwyg pt-40 md:py-80" v-html="ecoChargeInfo?.text"></div>
                <div class="border-t border-b my-32 flex flex-col gap-8 border-sand-30 py-8">
                    <div class="flex justify-between gap-8">
                        <div>{{ ecoChargeInfo?.totalPriceLabel }}</div>
                        <div>{{ ecoChargeInfo?.totalPrice?.formatted }}</div>
                    </div>
                    <div class="flex justify-between gap-8">
                        <div>{{ ecoChargeInfo?.ecoChargeShareLabel }}</div>
                        <div>{{ ecoChargeInfo?.ecoChargeSharePrice?.formatted }}</div>
                    </div>
                </div>
            </div>
        </div>
    </PortalOverlay>
</template>

<script setup lang="ts">
import { computed, ref, onUnmounted, watch } from 'vue';
import { v4 } from '@/types/serverContract';
import bus from '@/core/bus';
import { OpenEcoChargeKey } from '@/project/config/constants';
import BreakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';

// TODO: ShippingInformationOverlay and this could use same component
const modalOpen = ref(false);
const side = ref('bottom');
const selectedVariant = ref<v4.Products.VariantSimple | undefined>(undefined);
const ecoChargeInfo = computed(() => selectedVariant?.value?.ecoCharge);

const openEcoChargeOverlay = (variant: v4.Products.VariantSimple) => {
    selectedVariant.value = variant;
    modalOpen.value = true;
};

const activeBreakpoint = computed(() => {
    return BreakpointsState.activeBreakpoint;
});

watch(activeBreakpoint, () => {
    const isMinMd = BreakpointsState.isBreakpointActive('min-md');
    side.value = isMinMd ? 'right' : 'bottom';
}, { immediate: true });

bus.on(OpenEcoChargeKey, openEcoChargeOverlay);

onUnmounted(() => {
    bus.off(OpenEcoChargeKey, openEcoChargeOverlay);
});

</script>
