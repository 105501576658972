<template>
    <div>
        <FavoriteToggle favorite-type="Product"
                        :favorite-identifier="line.sku"
                        :show-button-text="false"
                        :icon-width="getIconWidth"
                        :tracking-event-label="getTrackingEvent.label"
                        :tracking-event-action="getTrackingEvent.action"
                        :tracking-event-product="getTrackingEvent.product"
                        :tracking-event-variant-sku="getTrackingEvent.variantSku"
                        tracking-list="basket"
                        button-class="mt-4"
                        button-class-favorite="text-brown-80 hover:text-white-180"
                        button-class-not-favorite="text-white-180 hover:text-brown-80"
                        :add-favorite-label="labelAddFavorite"
                        :remove-favorite-label="labelRemoveFavorite"
                        :always-add="false"
                        :class="{'opacity-0': showMovingToFavoriteMessage}"
                        style="transition: opacity 0.5s"
                        :use-smaller-popup-top-margin="true"
                        @addingToFavorite="onAddToFavoriteInitialized"
                        @loginOpen="onLoginOpen"/>
        <transition appear
                    enter-active-class="animated fadeIn u-anim-dur-300"
                    leave-active-class="animated fadeOut u-anim-dur-300">
            <div v-if="showMovingToFavoriteMessage"
                 class="flex absolute bottom-0 left-0 right-0 z-1 bg-sand-20 py-8 px-16">
                <div class="flex justify-between w-full items-center">
                    <div class="font-gibson-regular text-14 leading-16 w-90 md:w-auto">
                        {{ $translate('checkout.Basket.Line.ItemMovedToFavorite') }}
                    </div>
                    <div
                        class="font-brandon-medium text-12 leading-12 uppercase tracking-0.6 justify-center underline pl-10 py-5 cursor-pointer"
                        @click="onCancelMovingToFavoriteClick">
                        {{ $translate('checkout.Basket.Line.CancelMovingItemToFavorite') }}
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { v4 } from '@/types/serverContract';
import Api, { BasketLineWithNewProduct } from '@/project/http/api';
import translateFilter from '@/core/translation/translate.filter';
import userStore, { ISessionStorageEventActionKey, ISessionStorageEventActionOn } from '@/store/user.store';
import breakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';
import trackingUtils from '@/project/tracking/tracking.utils';

const FavoriteToggle = () => import('@/project/favorites/FavoriteToggle.vue');

interface TrackingEvent {
    label: string;
    action: string;
    product: v4.Products.ProductSimple;
    variantSku: string;
}

@Component({
    components: { FavoriteToggle }
})
export default class LineItem extends Vue {
    @Prop() line!: BasketLineWithNewProduct;
    @Prop() variantName!: string;

    showMovingToFavoriteMessage: boolean = false;

    timerId: number = 0;

    @Watch('showMovingToFavoriteMessage')
    onShowingMessageChange(newValue: boolean) {
        this.$emit('movingMessageVisibilityChange', newValue);
    }

    onAddToFavoriteInitialized() {
        this.showMovingToFavoriteMessage = true;
        this.timerId = setTimeout(() => {
            this.$emit('addToFavourite');
            this.showMovingToFavoriteMessage = false;
        }, 2500);
    }

    onCancelMovingToFavoriteClick() {
        clearTimeout(this.timerId);
        Api.user.removeFavorite(this.line.sku, 'Product');
        trackingUtils.basket.trackRegretMoveFromBasketToFavorite(this.line.product);
        trackingUtils.favourites.trackRemoveFromWishlist('Product', this.line.product);
        this.showMovingToFavoriteMessage = false;
    }

    onLoginOpen() {
        userStore.setPostPageLoadAction({
            key: ISessionStorageEventActionKey.ShowBasketOverlay,
            on: ISessionStorageEventActionOn.PageLoadedUserAuthenticated,
            ttl: 300000
        });
    }

    get getTrackingEvent(): TrackingEvent {
        return {
            label: 'BasketOverlay',
            action: this.line.product.name + '_' + this.variantName,
            product: this.line.product,
            variantSku: this.line.sku
        };
    }

    get getTrackingEventAction(): string {
        return this.line.product.name + '_' + this.variantName;
    }

    get labelAddFavorite(): string {
        return translateFilter('checkout.Basket.Line.MoveToFavorite', translateFilter('myPage.FavoriteProducts.Title'));
    }

    get labelRemoveFavorite(): string {
        return translateFilter('checkout.Basket.Line.RemoveFavorite', translateFilter('myPage.FavoriteProducts.Title'));
    }

    get getIconWidth(): string {
        if (breakpointsState.isBreakpointActive('min-md')) {
            return '24';
        } else {
            return '20';
        }
    }
}
</script>
