<template>
    <div class="min-w-48 relative">
        <ResponsiveIcon
            :image-url="label.icon?.url"
            :width="(label.icon?.width ?? 0) * 2"
            :height="(label.icon?.height ?? 0) * 2"
            :alt="label.name"
            :quality="80"
            class="h-48 mx-8 w-auto"
            format="webp"
            bg-color="FFFEF8"/>

        <div v-if="label.subtitle"
             class="text-brown-60 font-gibson-light text-8 leading-12 mt-8 relative">
            <a v-if="getUrl"
               :href="getUrl"
               :title="label.name"
               target="_blank"
               class="underline decoration-color-brown-40 mx-auto inline-block hover:no-underline"
               :style="{maxWidth: maxIconSize}">
                {{ label.subtitle }}
            </a>
            <span v-else
                  class="block mx-auto whitespace-pre-line"
                  :style="{maxWidth: maxIconSize}"
                  v-html="formatValue(props.label.subtitle)">
            </span>
        </div>

        <div v-if="label.customLabelText"
             class="text-center w-full flex h-48 font-gibson-regular leading-8 absolute inset-0"
             style="color: #000; font-size: 6px">
            <div class="ml-auto mb-auto mt-26 mr-10"
                 style="width: 54px"
                 v-html="formatValue(props.label.customLabelText)">
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import ResponsiveIcon from '@/core/responsive/image/ResponsiveIcon.vue';
import { v4 } from '@/types/serverContract';
import { computed, defineProps } from 'vue';
import { newLineToBr } from '@/project/shared/string.util';

const props = defineProps<{
    label: v4.Products.Label | v4.Products.EnergyClassLabel,
}>();

const getUrl = computed(() => {
    if ('url' in props.label) {
        return props.label.url;
    }
    return '';
});

const formatValue = (val) => {
    return newLineToBr(val, /\r\n/g);
};

const maxIconSize = computed(() => {
    return props.label?.icon?.aspectRatio ? Math.max((48 * props.label.icon.aspectRatio), 64) + 'px' : 64 + 'px';
});

</script>

<style scoped lang="less">
</style>
