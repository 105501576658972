<template>
    <div v-if="showFreeShippingBanner" class="flex w-full justify-center px-8 py-25">
        <cIcon name="delivery" width="20" class="mr-16"/>
        <span class="text-14 font-gibson-regular leading-18 md:text-16">{{ labelForFreeShippingBanner }}</span>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import basketStore from '@/store/basket.store';
import translateFilter from '@/core/translation/translate.filter';
import currencyFilter from '@/project/shared/currency/currency.filter';

@Component
export default class BasketFreeShippingBanner extends Vue {
    get showFreeShippingBanner(): boolean {
        return (
            !basketStore.modifiedByServer && // Do not show banner if basket contains products that no longer can be bought og products where the price has been changed
            basketStore.freeShippingLimit != null
        );
    }

    /**
     *
     * @param amountFromLimit How much more the user needs to spend in order to obtain free delivery
     */
    createLabel(amountFromLimit: number): string {
        if (amountFromLimit > 0) {
            return translateFilter('checkout.Basket.FreeDelivery.ShopMoreToGet', currencyFilter(amountFromLimit, true));
        } else {
            return translateFilter('checkout.Basket.FreeDelivery.Obtained');
        }
    }

    get labelForFreeShippingBanner(): string {
        if (!this.showFreeShippingBanner) {
            return '';
        }
        const totalInBasket = basketStore.basket?.totalInclVat || 0;
        const toLimit = (basketStore?.freeShippingLimit || 0) - totalInBasket;
        return this.createLabel(toLimit);
    }
}
</script>
