<template>
    <div class="o-container">
        <div class="md:flex">
            <div class="mx-auto w-120 md:w-2/5 text-center md:px-40" :class="{' pb-80 md:pb-10': content.image}">
                <responsive-icon-wrap v-if="content.image" :image="content.image" :alt-text="content.heading" :bg-color="content.backgroundColor || ''"/>
            </div>
            <div class="md:w-3/5">
                <component :is="getHeaderTagType" v-if="content.heading" class="uppercase font-light text-25 md:text-30 mb-30 leading-normal">
                    {{ content.heading }}
                </component>
                <div v-if="content.teaserText" class="text-20 leading-teaser md:text-26 mb-40 md:mb-50">
                    {{ content.teaserText }}
                </div>
                <div v-delegate-html-links class="wysiwyg leading-teaser md:text-18 md:max-w-4/5" v-html="content.bodyText"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { TextSectionBlockModel } from '@/types/serverContract';

@Component({
    name: 'BlockTextSection'
})
export default class BlockTextSection extends Vue {
    @Prop() content!: TextSectionBlockModel;

    get getHeaderTagType() : string {
        return this.content.headerType || 'h3';
    }
}
</script>
