<template>
    <div class="relative flex h-21 w-36">
        <cIcon class="-mt-1 h-16 w-20 bg-transparent md:h-19" name="cart"/>
        <div class="absolute inset-0">
            <div class="absolute inset-y-0 left-1/2 -mt-7 ml-5 -translate-x-1/2 transform">
                <Transition mode="out-in" name="badge" @after-enter="afterEnter">
                    <div
                        :key="localBasketCount"
                        class="badge flex h-16 items-center justify-center rounded-full bg-brown-80 px-4 text-center text-12 font-brandon-regular leading-none text-sand-10">
                        {{ localBasketCount }}
                    </div>
                </Transition>
                <dotlottie-player
                    v-show="showGlitter"
                    key="glitter"
                    ref="lottieInstance"
                    :src="basketGlitter"
                    class="glitter absolute top-0 -translate-y-6 transform"
                    :style="`--transform-translate-x: ${badgeWidth}px`"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, onBeforeUnmount, ref, watch } from 'vue';
import type { DotLottiePlayer } from '@dotlottie/player-component';
import basketStore from '@/store/basket.store';
import { navigationbarStore } from '@/store/navigationbar.store';
import { basketGlitter } from '@/project/shared/lottie/lottiejson';

const props = defineProps<{
    animate?: boolean;
}>();

onBeforeUnmount(() => {
    lottieInstance.value = null;
});

const badgeWidth = ref(12); // 16px default badge width - 4px offset
const lottieInstance = ref<DotLottiePlayer | null>(null);
const showGlitter = ref(false);

const basketCount = computed(() => basketStore.itemCount || 0);
const localBasketCount = ref<number>(basketCount.value);
const updateBasketCount = (newVal: number, delay: number = 0) => {
    if (newVal === localBasketCount.value) {
        return;
    }

    setTimeout(() => {
        localBasketCount.value = newVal;
    }, delay);
};

const afterEnter = (el: Element) => {
    const { clientWidth } = el;
    if (clientWidth > 0) {
        badgeWidth.value = clientWidth - 4;
        showGlitter.value = true;
    }
};

watch(
    basketCount,
    (basketCount, oldBasketCount) => {
        if (basketCount === oldBasketCount) {
            return;
        }

        // wait for navigation bar animate in before updating basket count - if `animate` is enabled
        const delay = props.animate && navigationbarStore.hidden && navigationbarStore.animateIn ? 800 : 0;
        updateBasketCount(basketCount, delay);
    },
    { immediate: true }
);

watch(lottieInstance, (lottieInstance) => {
    if (!lottieInstance) {
        return;
    }

    lottieInstance.addEventListener('complete', () => {
        showGlitter.value = false;
    });
});

watch(showGlitter, (showGlitter) => {
    if (showGlitter && lottieInstance.value) {
        lottieInstance.value.seek(0);
        lottieInstance.value.play();
    }
});
</script>

<style scoped>
.badge {
    min-width: 1.6rem;
}

.badge-enter-active,
.badge-leave-active {
    transition: transform 200ms;
}

.badge-enter-to,
.badge-leave-from {
    transform: scale(1.3);
}

.glitter {
    width: 0.8rem;
    height: 0.9rem;
}
</style>
