<template>
    <Transition
        mode="out-in"
        leave-active-class="animated fadeOut u-anim-dur-300"
        enter-active-class="animated fadeIn u-anim-dur-300">
        <div class="relative">
            <div class="absolute left-1/2 top-0">
                <div v-if="message" class="transform -translate-x-1/2 fixed z-6"
                     :class="positioning">
                    <div
                        v-if="isFavoriteToast(message.type)"
                        class="c-toast flex gap-8 rounded-2 bg-brown-70 p-16 text-sand-10">
                        <p class="flex-1 text-14 font-gibson-light leading-18" v-html="message.message"></p>
                        <button :aria-label="$translate('generic.Close')" class="self-start p-8 -m-8" @click="dismiss">
                            <c-icon name="close" width="12"/>
                        </button>
                    </div>
                    <div v-else
                         class="flex items-center gap-8 rounded-2 bg-brown-70 p-8 text-sand-10">
                        <c-icon name="checkmark-frame" width="20"/>
                        <p class="flex-1 text-14 font-gibson-regular leading-18" v-html="message.message"></p>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import { nextTick, onBeforeUnmount, onMounted, ref, defineProps, withDefaults } from 'vue';
import bus from '@/core/bus';
import { ToastMessage, TOAST_BUS_KEY } from '@/project/config/constants';

withDefaults(defineProps<{
    positioning?: string,
}>(), {
    positioning: 'top-110 md:top-140'
});

const timer = ref<number | null>(null);
const message = ref<ToastMessage|null>(null);

onMounted(() => {
    bus.on(TOAST_BUS_KEY, onMessages);
});

onBeforeUnmount(() => {
    bus.off(TOAST_BUS_KEY, onMessages);
    if (timer.value) clearTimeout(timer.value);
});

const onMessages = (msg: ToastMessage) => {
    if (timer.value) clearTimeout(timer.value);

    const time = isRemoveToast(msg.type) ? 3_000 : 5_000;
    timer.value = setTimeout(() => dismiss(), time);

    if (msg.message !== message.value?.message) {
        message.value = null;
        nextTick(() => {
            message.value = msg;
        });
    } else {
        message.value = msg;
    }
};

const dismiss = () => {
    message.value = null;
};

const isRemoveToast = (type: ToastMessage['type']) => {
    return ['FAVORITE_REMOVE', 'REMOVE_FROM_CART'].includes(type);
};

const isFavoriteToast = (type: ToastMessage['type']) => {
    return ['FAVORITE_ADD', 'FAVORITE_REMOVE'].includes(type);
};
</script>

<style scoped>
a {
    color: currentColor;
}

.c-toast {
    width: calc(100vw - 4rem);
    max-width: 35rem;
}

</style>
