<template>
    <div>
        <div class="flex items-center mb-8 leading-16">
            <span class="font-gibson-regular uppercase mr-16 text-14 text-brown-80">
                {{ $translate('store.MyStore') }}
            </span>
            <button
                class="text-brown-65 text-14 underline hover:no-underline"
                @click="open = true">
                {{ $translate('store.ChangeStore') }}
            </button>
        </div>
        <div class="leading-18 text-14 font-gibson-regular mb-8 text-brown-80">
            {{ myStoreData?.name }}
        </div>
        <Transition mode="out-in"
                    leave-active-class="animated fadeOut u-anim-dur-200"
                    enter-active-class="animated fadeIn u-anim-dur-200">
            <StockStatusIndicator v-if="myStoreData && myStoreData.inventory"
                                  :key="selectedVariant.sku"
                                  :class="{'blur': state === PendingState.Pending}"
                                  :inventory="myStoreData.inventory[0]"/>
        </Transition>

        <StoreOverlayWrapper
            :mode="StoreOverlayMode.StorePicker"
            :show.sync="open"
            :details="details"
            :selected-variant="selectedVariant"
            @cancelled="handleClose"/>
    </div>
</template>

<script lang="ts" setup>
import { StoreInventoryState, v4 } from '@/types/serverContract';
import { defineProps, ref, watch } from 'vue';
import http from '@/core/http/http.service';
import StockStatusIndicator from '@/project/product/StockStatusIndicator.vue';
import { StoreOverlayMode, PendingState } from '@/types/frontend';
import StoreOverlayWrapper from '@/project/product/StoreOverlayWrapper.vue';
import mParticleUtils from '@/project/tracking/mParticle.utils';

const open = ref(false);
const state = ref(PendingState.Resolved);

const props = defineProps<{
    selectedVariant: v4.Products.ProductDetailsVariant;
    details: v4.Products.ProductDetails;
}>();

const myStoreData = ref<v4.Stores.StoreWithInventory | null>(null);

const getMyStore = async() => {
    state.value = PendingState.Pending;
    const result = await http.get<v4.Stores.StoreWithInventory>(`/store/favorite?sku=${props.selectedVariant.sku}`);
    myStoreData.value = result;
    state.value = PendingState.Resolved;

    trackMyStoreStockStatus();
};

const handleClose = () => {
    open.value = false;
    getMyStore();
};

watch(() => props.selectedVariant.sku, (val, oldVal) => {
    if (val && val !== oldVal) {
        getMyStore();
    }
}, { immediate: true });

function trackMyStoreStockStatus() {
    if (myStoreData.value?.inventory?.[0]?.state === StoreInventoryState.Unspecified) {
        mParticleUtils.store.trackMyStoreUnknownStockStatus();
    }
}

</script>

<style scoped>

.blur {
    filter: blur(3px);
}
</style>
