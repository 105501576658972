<template>
    <div class="responsive-image responsive-image--loaded" :style="style">
        <picture v-if="imageUrl"
                 ref="picture">
            <source v-if="isWebpWithFallback"
                    key="webpFallbackSrc"
                    type="image/webp"
                    :srcset="srcset"
                    :sizes="sizesFromWidthOnScreen">
            <source :srcset="fallbackSrcset"
                    :sizes="sizesFromWidthOnScreen">
            <img ref="image"
                 v-on-error
                 :alt="alt"
                 :src="src"
                 :loading="loadType">
        </picture>
    </div>
</template>

<script setup lang="ts">
import { defineProps, ref, computed, onMounted, PropType } from 'vue';
import sortBy from 'lodash-es/sortBy';
import keys from 'lodash-es/keys';
import zipObject from 'lodash-es/zipObject';
import map from 'lodash-es/map';
import breakpointService from '../breakpoints/breakpoints.service';
import loggingService from '../../logging.service';
import breakpointsState from '../breakpoints/breakpointsState.observable';
import constants from '@/project/config/constants';
import spaStore from '@/core/spa/store/spa.store';
import { isPrerenderUseragent } from '@/project/shared/string.util';

interface IResponsiveImageWidthOnScreenConfig {
  [key: string]: number;
}

interface IFocalPoint {
  left: number;
  top: number;
}

const props = defineProps({
    imageUrl: { type: String, default: '' },
    widthOnScreen: { type: [Object, Number], default: 100 },
    aspectRatio: { type: [Number, Object], default: 16 / 9 },
    offset: { type: Number, default: 200 },
    bgColor: { type: String, default: 'transparent' },
    quality: { type: Number, default: 75, validator: (value: number) => value >= 1 && value <= 100 },
    alt: { type: String, default: '' },
    focalPoint: { type: Object as PropType<IFocalPoint>, default: undefined },
    mode: { type: String, default: 'crop', validator: (value: string) => ['crop', 'pad'].includes(value) },
    format: { type: String, default: 'jpg', validator: (value: string) => ['webp', 'jpg', 'png', 'gif', '.webp', '.jpg', '.png', '.gif'].includes(value) },
    loadType: {
        type: String,
        default: 'lazy',
        validator: (value: string) => ['lazy', 'eager'].includes(value)
    }
});

const sizes = [320, 640, 1024, 1600];
const hasSrcsetSupport = ref(true);
const altText = ref(props.alt);
const image = ref<HTMLImageElement | null>(null);

onMounted(() => {
    altText.value = props.alt || spaStore.pageData.metadata.seoTitle;
    hasSrcsetSupport.value = !isPrerenderUseragent() && image.value ? 'sizes' in image?.value : true;
});

const formatNormalized = computed(() => {
    return props.format.replace('.', '');
});

const src = computed(() => {
    return fallbacksrc.value;
});

const srcset = computed(() => {
    let srcset = '';
    if (hasSrcsetSupport.value) {
        srcset = sizes
            .map(width => {
                return formatImageUrl(width, heightFromWidth(width)) + ` ${width}w`;
            })
            .join(', ');
    }
    return srcset;
});

const fallbacksrc = computed(() => {
    const width = sizes[sizes.length - 2]; // second largest
    return formatImageUrl(width, heightFromWidth(width));
});

const isWebpSrc = computed(() => {
    return props.imageUrl.indexOf('.webp') > -1;
});

const isWebpWithFallback = computed(() => {
    return isWebpSrc.value || formatNormalized.value === 'webp';
});

const sizesFromWidthOnScreen = computed(() => {
    let sizesStr = '';
    if (typeof props.widthOnScreen === 'object') {
        sizesStr = map(sortConfigBySize(props.widthOnScreen), (width, screen) => {
            if (!breakpointService.getBreakpoint(screen)) {
                const errorMessage = `ResponsiveImage: Breakpoint ${screen} is not defined for imageUrl ${props.imageUrl}`;
                loggingService.debug(errorMessage);
                loggingService.warn(errorMessage);
            }
            return `(min-width: ${breakpointService.getBreakpoint(screen).min}px) ${width}vw`;
        }).join(',');
        sizesStr += ', 100vw';
    } else {
        sizesStr = `${props.widthOnScreen}vw`;
    }
    return sizesStr;
});

const style = computed(() => {
    const padding = `${(100 / activeAspectRatio.value).toFixed(2)}%`;
    return { paddingTop: padding, background: `#${normalizedBgColor.value}` };
});

function heightFromWidth(width: number) {
    return Math.round(width / activeAspectRatio.value);
}

const activeAspectRatio = computed(() => {
    if (typeof props.aspectRatio === 'number') {
        return props.aspectRatio;
    }
    const activeBpDef = Object.keys(props.aspectRatio).find(bpDef => breakpointsState.isBreakpointActive(bpDef));
    const result = activeBpDef ? props.aspectRatio[activeBpDef] : props.aspectRatio.default;
    if (!result) {
        throw new Error('Provide a breakpoint-definition for all cases or a default');
    }
    return result;
});

function formatImageUrl(width: number, height: number, quality?: number): string {
    if (!props.imageUrl) {
        return constants.NoImageImgSrcBase64;
    }
    const firstSeparator = !props.imageUrl || props.imageUrl.indexOf('?') === -1 ? '?' : '&';
    const focal = props.focalPoint ? `&rxy=${props.focalPoint.left},${props.focalPoint.top}` : '';
    const bgColor = normalizedBgColor.value === 'transparent' ? '' : `&bgcolor=${normalizedBgColor.value}`;
    const format = props.imageUrl.indexOf('.gif') > 0 ? 'gif' : formatNormalized.value;
    return `${props.imageUrl}${firstSeparator}format=${format}&width=${width}&height=${height}&quality=${quality || props.quality}&mode=${props.mode}${focal}${bgColor}`;
}

function sortConfigBySize(config: IResponsiveImageWidthOnScreenConfig) {
    const sortedKeys = sortBy(keys(config), key => {
        return config[key];
    });
    return zipObject(
        sortedKeys,
        map(sortedKeys, function(key) {
            return config[key];
        })
    );
}

const fallbackSrcset = computed(() => {
    return srcset.value.split('format=webp').join('format=jpg');
});

const normalizedBgColor = computed(() => props.bgColor.replace('#', ''));

</script>
<style lang="less" >
.responsive-image {
    position: relative;
    overflow: hidden;
    height: 0;
    display: block;
    transition: opacity .3s cubic-bezier(.215,.61,.355,1);
    opacity: 0;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &.responsive-image--loaded {
        opacity: 1;
    }
}

.responsive-image--is-parallax {
    height: 100%;
    padding: 0 !important;

    img {
        position: relative;
        height: 100%;
        max-width: none;
        width: 100%;
        object-fit: cover;
        object-position: top;
        font-family: "object-fit: cover; object-position: top";
    }
}
</style>
