<template>
    <nav class="bg-white-100">
        <FlickityWrap
            v-if="showFlickity"
            ref="flickityRef"
            class="flickity-wrap"
            :class="{ 'with-images': showImages }"
            :options="flickityOptions"
            :handle-select="false"
            :scroll-wheel="true"
            @init="selectActiveCategory">
            <template v-if="showImages">
                <router-link
                    v-for="(category, ix) in categories"
                    :key="ix"
                    class="category-image-container router-link-exact-active-exempt group flex max-w-120 flex-col items-center"
                    :to="category.nameAndUrl.url">
                    <div
                        class="category-image flex items-center justify-center rounded-full bg-white-325 p-10 transition-colors duration-300 ease-in-out md:group-hover:bg-white-520">
                        <ResponsiveIcon
                            v-if="category.image && category.image.url"
                            :image-url="category.image.url"
                            :width="240"
                            :height="(240 * category.image.width) / category.image.height"
                            format="png"
                            bg-color="transparent"
                            :alt-text="category.image.name"/>
                    </div>
                    <UmbracoText
                        :class="getMaxWidthClassForColumn(ix, categories?.length ?? 0)"
                        class="leading-15 mx-8 mt-8 whitespace-pre-line text-center font-brandon text-13 font-normal uppercase text-brown-80 transition-colors duration-300 ease-in-out md:max-w-120"
                        style="overflow-wrap: anywhere"
                        :text="category.nameAndUrl.name"
                        tag="div"/>
                </router-link>
            </template>
            <template v-else>
                <router-link
                    v-for="category in subCategories"
                    :key="category.nameAndUrl.url"
                    class="whitespace-no-wrap mx-5 flex h-40 items-center rounded-3 bg-white-325 px-16 py-10 text-brown-80 transition-colors duration-300 ease-in-out md:group-hover:bg-sand-40"
                    :class="[
                        { 'router-link-selected': isCurrentUrl(category.nameAndUrl.url) },
                        { 'router-link-selected': isShowingAll }
                    ]"
                    :to="category.nameAndUrl.url">
                    <span
                        class="font-brandon text-13 font-normal uppercase leading-none transition-colors duration-300">{{ category.nameAndUrl.name }}</span>
                </router-link>
            </template>
        </FlickityWrap>
    </nav>
</template>

<script setup lang="ts">
import { ref, watch, nextTick, computed, defineProps, onMounted, onUnmounted } from 'vue';
import debounce from 'lodash-es/debounce';
import FlickityWrap from '@/core/FlickityWrap.vue';
import { NavigationLinkItem, NavigationLinkType, Media } from '@/types/serverContract';
import ResponsiveIcon from '@/core/responsive/image/ResponsiveIcon.vue';
import spaStore from '@/core/spa/store/spa.store';
import { useRoute } from 'vue-router/composables';
import translateFilter from '@/core/translation/translate.filter';
import viewportEvents, { Viewport } from '@/core/responsive/viewport/viewportEvents.service';

const route = useRoute();

const props = defineProps<{
    categories?: NavigationLinkItem[];
    showImages?: boolean;
}>();

const showFlickity = ref(false);
const flickityRef = ref<FlickityWrap | null>(null);
const itemWidth = ref(120);
const pageWidth = ref(0);

const parentUrl = computed(() => {
    return spaStore.navigation.breadcrumb.slice(-1)[0].url;
});

const handleResize = debounce((viewport: Viewport, timestamp: number) => {
    pageWidth.value = viewport.width;
}, 500);

onMounted(() => {
    viewportEvents.setCallback(handleResize);
});

onUnmounted(() => {
    viewportEvents.removeCallback(handleResize);
});

const isCurrentUrl = (url: string) => {
    return url === route.path;
};

const isShowingAll = computed(() => {
    return spaStore.navigation.breadcrumb.length === 2;
});

const activeSubCategoryIndex = computed(() => {
    const activeCategoryIndex = props.categories?.findIndex((c) => c.nameAndUrl.url === route.path);
    return activeCategoryIndex;
});

const isCurrentUrlInCategories = computed(() => {
    return props.categories?.some((c) => c.nameAndUrl.url === route.path);
});

const showAllSubCategories = computed<NavigationLinkItem>(() => {
    return {
        image: {} as Media,
        nameAndUrl: {
            name: translateFilter('navigation.Categories.AllSubCategories'),
            url: isCurrentUrlInCategories.value ? parentUrl.value : ''
        },
        subNavigationLinks: [],
        type: NavigationLinkType.Products
    };
});

const subCategories = computed(() => {
    if (!props.categories?.length) return null;
    return [showAllSubCategories.value, ...props.categories];
});

const getMaxWidthClassForColumn = (columnIndex: number, numColumns: number) => {
    if (columnIndex === 0 || columnIndex === numColumns - 1) {
        return 'max-w-88';
    } else {
        return 'max-w-120';
    }
};

const flickityOptions = {
    imagesLoaded: true,
    prevNextButtons: false,
    contain: true,
    cellAlign: 'center',
    freeScroll: true,
    draggable: '>1',
    pageDots: false
};

watch(
    () => props.categories,
    () => {
        initFlickity();
    },
    { immediate: true }
);

function initFlickity() {
    showFlickity.value = false;
    nextTick(() => {
        showFlickity.value = true;
    });
}

const selectActiveCategory = () => {
    if (activeSubCategoryIndex.value !== undefined) {
        return flickityRef.value?.select(activeSubCategoryIndex.value, false, true);
    }
};

/**
 * Set the item width based on the number of items that should be visible in the carousel
 * Note: we watch `pageWidth` to make sure `itemWidth` is re-calculated when the page is resized
 */
watch(
    [() => props.categories?.length, flickityRef, pageWidth],
    ([categoriesCount, flickity]) => {
        if (!categoriesCount || !flickity) return;

        const containerWidth = flickity.$el.clientWidth;
        const itemMaxWidth = 120;

        let maxFullItems = Math.floor(containerWidth / itemMaxWidth);
        maxFullItems += 0.5;

        const itemSize =
            itemMaxWidth * categoriesCount < containerWidth
                ? itemMaxWidth
                : Math.min(itemMaxWidth, Math.floor(containerWidth / maxFullItems));

        itemWidth.value = itemSize;
        flickity.resize();
    },
    { immediate: true }
);
</script>

<style scoped>
.category-image-container {
    width: calc(v-bind(itemWidth) * 1px);
}

.category-image {
    aspect-ratio: 1;
    width: calc((88 / 120 * v-bind(itemWidth)) * 1px);
}

.router-link-exact-active,
.router-link-selected {
    @apply bg-brown-80 text-white-100;
}

.router-link-exact-active-exempt.router-link-exact-active {
    @apply bg-transparent text-white-100;
}

.flickity-wrap.with-images {
    @apply relative left-1/2 right-1/2 -mx-1/2-screen w-screen;
}

@screen md {
    .flickity-wrap.with-images {
        @apply static mx-auto w-full;
    }
}
</style>
