<template>
    <div :id="getHeaderAnchorLink" class="overflow-hidden">
        <div v-if="content.title" class="pb-20 md:pb-40">
            <span class="uppercase text-13 opacity-75 tracking-medium">{{ content.title }}</span>
        </div>
        <div v-if="content.image" class="-mx-20 sm:-mx-40 lg:mx-0">
            <responsive-image class="w-full"
                              :width-on-screen="{xs:100}"
                              :aspect-ratio="{
                                  'max-sm': 375/560,
                                  'min-ls': 1320/700
                              }"
                              :image-url="content.image.url" :focal-point="content.image && content.image.focalPoint"
                              :alt="altText"/>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ImageBlockModel } from '@/types/serverContract';
import { getHeaderAnchorLink } from '@/project/shared/string.util';

@Component({
    name: 'BlockImage'
})
export default class BlockImage extends Vue {
    @Prop() content!: ImageBlockModel;

    get getHeaderAnchorLink(): string | undefined {        return getHeaderAnchorLink(this.content);
    }

    get altText(): string | null {
        if (this.content.altText) {
            return this.content.altText;
        }
        return this.content.title;
    }
}
</script>
