<template>
    <header class="o-page-header" :class="getPageClasses" :aria-label="$translate('generic.AriaLabels.PageHeader')">
        <transition mode="out-in" leave-active-class="max-h-0" enter-active-class="max-h-0">
            <NotificationBar v-if="getNotificationBar"/>
        </transition>
        <div class="navigation-bar-wrapper h-52 xl:h-60">
            <div
                ref="navigationBar"
                class="transition z-header duration-500"
                :class="{
                    'navigation-bar--hidden fixed left-0 right-0': navigationBarHidden,
                    'navigation-bar--animate-in': navigationBarAnimateIn
                }">
                <DesktopNavigation
                    :search-label="labelToggleSearch"
                    @toggleSearch="toggleSearch"
                    @toggleBasket="toggleBasket"
                    @productsSelected="productsSelected"
                    @roomsSelected="roomsSelected"/>
                <div class="c-mobile-header">
                    <div class="c-mobile-header__nav">
                        <div class="c-mobile-header__nav-toggle">
                            <button
                                class="c-mobile-header__nav-toggle-btn"
                                :aria-label="$translate('generic.Open')"
                                @click="openNavigation">
                                <span/>
                                <span/>
                                <span/>
                            </button>
                        </div>
                        <div class="c-mobile-header__nav-logo relative" role="banner" :aria-label="headerNavigation?.frontPage?.name">
                            <RouterLinkConditional
                                v-if="headerNavigation?.frontPage"
                                :to="headerNavigation.frontPage.url"
                                :title="headerNavigation.frontPage.name"
                                tag="a"
                                class="absolute inset-0"/>
                            <cIcon name="logo-collapsed" class="text-brown-80"/>
                        </div>
                        <div class="flex-shrink hidden flex-1 justify-center ls:flex">
                            <button
                                class="w-80% cursor-pointer bg-white-300"
                                tabindex="-1"
                                @click="toggleSearch()"
                                @keydown="onSearchKeyDown">
                                <div class="pointer-events-none flex">
                                    <div class="flex flex-shrink-0 pl-10">
                                        <cIcon
                                            class="mr-5 flex-shrink-0 self-center bg-transparent"
                                            name="search"
                                            width="14"/>
                                    </div>
                                    <input
                                        ref="searchInput"
                                        v-prohibit-zoom
                                        type="search"
                                        :placeholder="$translate('search.Overlay.InputPlaceholder')"
                                        class="flex-shrink flex-grow c-search__input --full-placeholder-opacity block h-28 w-full min-w-0 bg-white-300 py-5 pl-5 pr-10 font-brandon text-12 uppercase md:text-13 md:leading-18">
                                </div>
                            </button>
                        </div>
                        <div class="ml-auto">
                            <ul class="c-mobile-header__nav-secondary">
                                <li class="search-icon" role="search">
                                    <button
                                        v-prohibit-zoom
                                        class="cursor-pointer text-brown-80"
                                        :aria-label="labelToggleSearch"
                                        @click="toggleSearch">
                                        <cIcon name="search" class="c-mobile-header__nav-secondary__search-icon"/>
                                    </button>
                                </li>
                                <li
                                    v-if="headerNavigation?.findStore?.url && headerNavigation.findStore.name"
                                    class="c-mobile-header__nav-secondary--optional mr-1">
                                    <RouterLinkConditional
                                        v-prohibit-zoom
                                        :to="headerNavigation.findStore.url"
                                        class="flex items-center"
                                        :title="headerNavigation.findStore.name">
                                        <cIcon name="shop" class="text-brown-80" height="20px"/>
                                    </RouterLinkConditional>
                                </li>
                                <li
                                    v-if="headerNavigation?.myPage?.url && headerNavigation.myPage.name"
                                    class="c-mobile-header__nav-secondary--optional">
                                    <RouterLinkConditional
                                        v-prohibit-zoom
                                        :to="headerNavigation.myPage.url"
                                        class="flex items-center"
                                        :title="headerNavigation.myPage.name">
                                        <cIcon name="user" class="text-brown-80" height="20px"/>
                                    </RouterLinkConditional>
                                </li>
                                <li
                                    v-if="headerNavigation?.favorites?.url"
                                    class="c-mobile-header__nav-secondary--optional">
                                    <RouterLinkConditional
                                        v-prohibit-zoom
                                        :to="headerNavigation.favorites.url"
                                        class="flex items-center"
                                        :title="headerNavigation.favorites.name">
                                        <cIcon name="heart" class="text-brown-80" height="17.5px"/>
                                    </RouterLinkConditional>
                                </li>
                                <li v-if="hasCheckout">
                                    <button
                                        v-prohibit-zoom
                                        class="cursor-pointer text-brown-80"
                                        :aria-label="labelToggleBasketAria"
                                        @click="toggleBasket">
                                        <span class="sr-only">{{ labelToggleBasket }}</span>
                                        <MiniBasket :animate="true"/>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <MobileMenuNavigation
            :navigation-active.sync="navigationActive"
            :initial-selection="initialNavigationSelection"/>
        <PortalOverlay
            portal="overlay"
            outer-class="c-search__overlay"
            :show.sync="isSearchActiveInternally"
            :disable-body-scroll="false"
            :show-close-button="false"
            side="right">
            <SearchOverlay class="z-3" @clearShow="closeSearch"/>
        </PortalOverlay>
    </header>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import { router } from '@/core/spa/router';
import navStore from '@/store/nav.store';
import basketStore from '@/store/basket.store';
import serverContext from '@/core/serverContext.service';
import DesktopNavigation from '@/project/shared/navigation/DesktopNavigation.vue';
import MobileMenuNavigation from '@/project/shared/navigation/MobileMenuNavigation.vue';
import translateFilter from '@/core/translation/translate.filter';
import spaStore from '@/core/spa/store/spa.store';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import trackingUtils from '@/project/tracking/tracking.utils';
import MiniBasket from '@/project/checkout/MiniBasket.vue';
import viewportEvents from '@/core/responsive/viewport/viewportEvents.service';
import bus from '@/core/bus';
import { navigationbarStore } from '@/store/navigationbar.store';
import {
    PageHeaderResetScrollBehaviour,
    PageHeaderStopScrollBehaviour,
    PageHeaderToggleHidden,
    PageHeaderToggleShowAtNextCategory
} from '@/project/config/constants';
import useHeaderNavigation from './useHeaderNavigation';

const NotificationBar = () => import('@/project/shared/NotificationBar.vue');
const SearchOverlay = () => import('@/project/search/SearchOverlay.vue');

const route = useRoute();
const { headerNavigation } = useHeaderNavigation();

const navigationBar = ref<HTMLElement | null>(null);
const navigationBarHidden = computed(() => navigationbarStore.hidden);
const navigationActive = ref<boolean>(false);
const isSearchActiveInternally = ref<boolean>(false);
const initialNavigationSelection = ref<'Root' | 'Products' | 'Rooms'>('Root');
const _scrollTrigger = ref<ScrollTrigger | null>(null);

const navigationBarAnimateIn = computed(() => navigationbarStore.animateIn);

onMounted(() => {
    gsap.registerPlugin(ScrollTrigger);
    initScrollBehaviour();
    bus.on(PageHeaderResetScrollBehaviour, initScrollBehaviour);
    bus.on(PageHeaderStopScrollBehaviour, stopScrollBehaviour);
    bus.on(PageHeaderToggleHidden, toggleHidden);
    bus.on(PageHeaderToggleShowAtNextCategory, toggleShowAtNextCategory);
    viewportEvents.setCallback(navigationbarStore.handleScroll);
});

onUnmounted(() => {
    viewportEvents.removeCallback(navigationbarStore.handleScroll);
});

const toggleHidden = (newVal: boolean) => {
    navigationbarStore.hidden = newVal;
};

const toggleShowAtNextCategory = (newVal: boolean) => {
    navigationbarStore.showAtNextCategory = newVal;
};

const initScrollBehaviour = () => {
    _scrollTrigger.value = ScrollTrigger.create({
        trigger: '.navigation-bar-wrapper',
        start: 'bottom top',
        end: 'max',
        invalidateOnRefresh: true,
        onEnter: () => toggleHidden(true),
        onLeaveBack: () => toggleHidden(false)
    });
};

const stopScrollBehaviour = () => {
    if (_scrollTrigger.value) _scrollTrigger.value.kill();
};

const getPageClasses = computed(() => {
    return navigationActive.value ? 'off-canvas-active' : 'print:hidden';
});

const hasCheckout = computed(() => {
    return serverContext.checkoutContext.hasCheckout || false;
});

const basketCount = computed(() => {
    if (hasCheckout.value && basketStore.basket && basketStore.itemCount !== null) {
        return basketStore.itemCount;
    }
    return null;
});

const storeNavigationActive = computed(() => {
    return navStore.navigationActive;
});

watch(storeNavigationActive, (newNavigationActive) => {
    if (!newNavigationActive) navigationActive.value = false;
});

watch(navigationActive, (newNavigationActive) => {
    navStore.setNavigationStateActive(navigationActive.value);
    if (!newNavigationActive) {
        initialNavigationSelection.value = 'Root';
    }
});

const onSearchKeyDown = (e) => {
    if (e.key !== 'Tab' && e.key !== 'Shift') {
        toggleSearch();
    }
};

const toggleSearch = () => {
    if (isSearchActiveInternally.value) {
        closeSearch();
    } else {
        openSearch();
    }
};

const closeSearch = (url: string | undefined = undefined) => {
    // Few links from search overlay contains url with term, dont clear term from url if it is intended.
    // And yes, it is intended to use both term and searchTerm.
    if (url?.indexOf('term=') === -1) {
        const query = Object.assign({}, route.query);
        delete query.searchTerm;

        // Dont change route if is is the same as current
        if (Object.keys(query).length !== Object.keys(route.query).length) {
            router.push({ query });
        }
    }
};

const openSearch = () => {
    router.push({ query: { searchTerm: '' } });
};

watch(
    () => route.query?.searchTerm,
    (searchTerm) => {
        isSearchActiveInternally.value = searchTerm !== undefined;
    }
);

watch(isSearchActiveInternally, (isSearchActiveInternally: boolean, oldIsSearchActiveInternally: boolean) => {
    if (!isSearchActiveInternally) {
        closeSearch('');
    } else {
        trackingUtils.overlay.trackOverlayOrMinipage({
            event: 'overlay',
            overlayName: 'Search Overlay'
        });
    }
});

const openNavigation = () => {
    initialNavigationSelection.value = 'Root';
    navigationActive.value = true;
};

const productsSelected = () => {
    initialNavigationSelection.value = 'Products';
    navigationActive.value = true;
};

const roomsSelected = () => {
    initialNavigationSelection.value = 'Rooms';
    navigationActive.value = true;
};

const toggleBasket = () => {
    basketStore.toggleBasketActiveState();
};

const basketActive = computed(() => {
    return basketStore.basketActive;
});

const getNotificationBar = computed(() => {
    return spaStore.notificationBar;
});

const labelToggleBasket = computed(() => {
    return toggleBasketLabelHelperFunction();
});

const labelToggleBasketAria = computed(() => {
    return toggleBasketLabelHelperFunction(true);
});

const labelToggleSearch = computed(() => {
    return translateFilter(isSearchActiveInternally.value ? 'search.Aria.Close' : 'search.Aria.Open');
});

const toggleBasketLabelHelperFunction = (includeBasketCount: boolean = false) => {
    return translateFilter(basketActive.value ? 'checkout.Basket.HideBasket' : 'checkout.Basket.ShowBasket') +
        includeBasketCount
        ? ` (${basketCount.value || 0})`
        : '';
};
</script>

<style lang="less" scoped>
.navigation-bar--hidden {
    top: -6.2rem;

    &.navigation-bar--animate-in {
        transform: translate3d(0, 6.2rem, 0);
    }
}

@screen md {
    .navigation-bar--hidden::after {
        @apply absolute bottom-0 left-0 right-0 top-50 -z-1 h-10 opacity-50;
        content: '';
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0) 100%);
    }
}

@screen xl {
    .navigation-bar--hidden::after {
        @apply top-60;
    }

    .navigation-bar--hidden {
        top: -7rem;

        &.navigation-bar--animate-in {
            transform: translate3d(0, 7rem, 0);
        }
    }
}
</style>
