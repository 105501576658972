<template>
    <div>
        <slot v-if="shouldShowComponent"/>
    </div>
</template>

<script lang="ts" setup>
// NOTE: This component can be used to wrap other elements,
// and show them based on a GrowthBook experiment.
// When using this component, consider importing the components async,
// so that only the needed components gets loaded.
import { onMounted, ref, defineProps, watch } from 'vue';
import useGrowthbook from '@/project/growthbook/useGrowthbook';

const props = defineProps<{
    variation: string;
    featureKey: string;
}>();

const shouldShowComponent = ref(false);
const { init, getFeatures, pending } = useGrowthbook();

onMounted(async() => {
    if (pending.value) return;
    await getFeatures();
});

watch(pending, (newVal, oldVal) => {
    if (!newVal) {
        getComponent();
    }
});

const getComponent = async() => {
    const growthBook = await init();

    if (!growthBook) {
        if (!props.variation) {
            return;
        }
        shouldShowComponent.value = true;
        return;
    }

    const variationKey = growthBook.getFeatureValue(props.featureKey, props.variation);
    shouldShowComponent.value = props.variation === variationKey;
};
</script>
