<template>
    <div>
        <slot :facets="facets" :activeSortByKey="activeSortByKey" :products="products" :totalCount="totalProductsCount"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import Api from '@/project/http/api';
import { Prop, Watch } from 'vue-property-decorator';
import urlHelper from '../facets/urlHelper.service';
import SearchFacets from './SearchFacets.vue';
import { Route } from 'vue-router';
import SearchSorting from '@/project/search/SearchSorting.vue';
import { FacetGroup, ProductSearchResultNew, v4 } from '@/types/serverContract';
import { doDataLayerPush } from '@/project/tracking/googleTagManager.utils';
import { format, isPrerenderUseragent } from '../shared/string.util';
import DomService from '@/core/dom.service';
import spaStore from '@/core/spa/store/spa.store';
import userStore from '@/store/user.store';
import ScrollService from '@/core/scroll/scroll.service';

@Component({
    name: 'SearchProductControl',
    components: { SearchFacets, SearchSorting }
})
export default class SearchProductControl extends Vue {
    productSearchResult: ProductSearchResultNew | null = null;
    currentPage = 1;
    productsResult: v4.Products.ProductSimple[] = [];
    isFirstPageHit: boolean = spaStore.isInitialPage; // the spa store will not change if just the category page is changing

    @Prop(String)
    categoryId: string | undefined;

    @Prop(String)
    brandId: string | undefined;

    @Prop(String)
    roomId: string | undefined;

    @Prop(String)
    trackingPageType!: string;

    @Prop(String)
    term!: string;

    @Prop({ type: Number, default: 28 }) pageSize!: number;

    @Prop(String)
    crossCategory: string | undefined;

    created() {
        if (isPrerenderUseragent()) {
            // Tell prerender to wait for search XHR
            // @ts-ignore
            window.prerenderReady = false;
        }
        this.search();
    }

    get totalProductsCount() : number {
        return this.productSearchResult ? this.productSearchResult.total : 0;
    }

    get facets(): FacetGroup[] {
        return this.productSearchResult ? this.productSearchResult.facets : [];
    }

    get products(): v4.Products.ProductSimple[] {
        return this.productsResult ? this.productsResult : [];
    }

    get activeSortByKey(): string {
        return this.productSearchResult ? this.productSearchResult.sortBy : '';
    }

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path === oldRoute.path) {
            this.search();
        }
    }

    @Watch('term')
    onTermChange() {
        this.productSearchResult = null;
        this.search();
    }

    @Watch('categoryId')
    onCategoryIdChange() {
        this.productsResult = [];
        this.productSearchResult = null;
        this.search();
    }

    @Watch('brandId')
    onBrandIdChange() {
        this.productsResult = [];
        this.productSearchResult = null;
        this.search();
    }

    @Watch('roomId')
    onRoomIdChange() {
        this.productsResult = [];
        this.productSearchResult = null;
        this.search();
    }

    searching(isSearching: boolean) {
        this.$emit('searching', isSearching);
    }

    async search() {
        // Only search if categoryId or term or crossCategory is set
        if (this.categoryId || this.brandId || this.term || this.crossCategory || this.roomId) {
            try {
                this.searching(true);

                // Adjust page and pagesize for first run, in order to get all products's up until current page.
                let realPageSize = this.pageSize;
                let realPage = urlHelper.getPage();
                if (!this.productSearchResult) {
                    realPageSize = urlHelper.getPage() * this.pageSize;
                    realPage = 1;
                }

                this.productSearchResult = await Api.catalog.search(
                    this.term,
                    this.categoryId,
                    this.brandId,
                    this.roomId,
                    urlHelper.getFacets(),
                    urlHelper.getSortBy(),
                    realPage,
                    realPageSize,
                    this.crossCategory);
                if (this.productSearchResult) {
                    if (!this.isFirstPageHit && realPage > 1) {
                        // Variables is used for tracking page changes on category pages
                        doDataLayerPush({
                            event: 'Variables',
                            LoggedInState: userStore.token ? 'LoggedIn' : 'LoggedOut',
                            pageType: spaStore.jsonContent && spaStore.jsonContent.alias
                        });
                    }
                    if (realPage === 1 && this.term) {
                        // Use timeout to allow Spa to finish and trigger 'Variables' event before this event
                        setTimeout(() => {
                            doDataLayerPush({
                                event: this.trackingPageType,
                                count: this.productSearchResult?.total,
                                searchTerm: this.term
                            });
                        }, 50);
                    }
                    const currentProducts = this.currentPage === urlHelper.getPage() ? [] : this.productsResult;
                    const previousScrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
                    this.productsResult = Object.freeze([...currentProducts, ...this.productSearchResult.searchResultItems]) as v4.Products.ProductSimple[];

                    // format meta description with number of products.
                    const metaDescription = format(spaStore.metadata.seoDescription, [this.productSearchResult?.total?.toString()]);
                    DomService.setMetaDescription(metaDescription);
                    DomService.setOpenGraphDescription(metaDescription);
                    if (isPrerenderUseragent()) {
                        // Tell prerender that the XHR was completed, but only on success
                        // @ts-ignore
                        setTimeout(() => window.prerenderReady = true, 500);
                    }

                    if (currentProducts.length > 0) {
                        this.$nextTick(() => {
                            ScrollService.simpleScroll(previousScrollPosition);
                        });
                    }
                    this.isFirstPageHit = false;
                }
            } finally {
                this.searching(false);
            }
        }
    }
}
</script>
