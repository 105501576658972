<template>
    <div>
        <ul>
            <li class="pt-50 pb-10">
                <SearchSortingButtons :active-key="activeSortByKey" class="-mr-2" :is-search-result="isSearchResult"/>
            </li>
            <li class="pt-10 pb-24">
                <SearchRangeFilter :facet-group="getMatchingFacetGroup(facets, 'unitPriceInclVat')"
                                   :title="$translate('search.Filters.Range')"/>
            </li>
            <li class="pt-24 pb-20 border-t border-white-200">
                <SearchColorFilter :facet-group="getMatchingFacetGroup(facets, 'color')"/>
            </li>
            <li class="py-24 border-t border-white-200">
                <SearchFilterButtons :facet-group="getMatchingFacetGroup(facets, 'material')"
                                     class-list-items="w-1/2 pl-10 pb-10"
                                     class-list-button="pt-10 pb-6"
                                     class-header="uppercase mb-10 text-10 tracking-medium-heading"
                                     button-background="bg-white-300"
                                     button-selected-background="bg-white-550"/>
            </li>
            <li v-if="!isSearchResult" class="pt-24 border-t border-white-200">
                <SearchBooleanFacet :facet-group="getMatchingFacetGroup(facets, 'inStock')" />
            </li>
        </ul>
    </div>
</template> 

<script setup lang="ts">
import { defineProps } from 'vue';
import { type FacetGroup } from '@/types/serverContract';
import SearchSortingButtons from '@/project/search/SearchSortingButtons.vue';
import SearchColorFilter from '@/project/search/SearchColorFilter.vue';
import SearchBooleanFacet from '@/project/search/SearchBooleanFacet.vue';
import SearchFilterButtons from '@/project/search/SearchFilterButtons.vue';
const SearchRangeFilter = () => import('@/project/search/SearchRangeFilter.vue');

withDefaults(defineProps<{
    facets: FacetGroup[];
    activeSortByKey: string;
    isSearchResult?: boolean;
}>(), {
    isSearchResult: false,
});

function getMatchingFacetGroup(facetGroups: FacetGroup[], key: string): FacetGroup | undefined {
    return facetGroups.find(f => f.key === key);
}

</script>