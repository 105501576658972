<template>
    <div v-if="isVideo" class="aspect-ratio-3-4 relative overflow-hidden">
        <SplitTest v-if="showFavoritedByBadge"
                   feature-key="fsst24-pdp-favorite-label"
                   variation="favorite-label-on">
            <FavoritesCountBadge
                :favorited-by="details.favoritedBy"
                :favorite-id="selectedVariant.sku"/>
        </SplitTest>
        <Video
            :key="media.url"
            :vimeo-video-url="`${media.url}#t=0.001`"
            :vimeo-video-id="vimeoUrlToId(media.url)"
            :vimeo-muted="true"
            :vimeo-loop="false"
            :play="shouldPlay"
            class="relative h-full w-full object-cover"
            @ended="handleVideoStreamEnded"
            @paused="handleVideoStreamEnded"/>
        <transition
            mode="out-in"
            leave-active-class="animated fadeOut u-anim-dur-200"
            enter-active-class="animated fadeIn u-anim-dur-200">
            <div v-if="!shouldPlay" class="absolute inset-0 z-1 flex cursor-pointer" @click="handleImageClick()">
                <button aria-label="generic.Open" class="m-auto" @click="handlePlayVideoClick()">
                    <cIcon name="play-video" original class="relative z-0 h-30 w-30 text-brown-80 md:h-40 md:w-40"/>
                </button>
            </div>
        </transition>
    </div>
    <div v-else class="relative">
        <SplitTest v-if="showFavoritedByBadge"
                   feature-key="fsst24-pdp-favorite-label"
                   variation="favorite-label-on">
            <FavoritesCountBadge
                :favorited-by="details.favoritedBy"
                :favorite-id="selectedVariant.sku"/>
        </SplitTest>
        <div class="relative flex w-full flex-col">
            <ResponsiveImage
                class="u-image-zoom h-auto"
                :width-on-screen="widthOnScreen"
                :aspect-ratio="3 / 4"
                :image-url="media.url"
                format="webp"
                :quality="80"
                bg-color="#faf7f0"
                load-type="eager"
                :width="width"
                :height="height"/>
            <a class="absolute inset-0 cursor-pointer" @click="handleImageClick()"></a>
        </div>
    </div>
</template>

<script setup lang="ts">
import bus from '@/core/bus';
import { Media, v4 } from '@/types/serverContract';
import { computed, defineProps, defineAsyncComponent } from 'vue';
import { PDP_IMAGE_FULLSCREEN } from '../productHelper.utils';
import { vimeoUrlToId } from '@/project/shared/string.util';
import useVideo from '@/project/product/details/useVideo';
import FavoritesCountBadge from '@/project/product/details/FavoritesCountBadge.vue';
import SplitTest from '@/project/growthbook/SplitTest.vue';
const Video = defineAsyncComponent(() => import('@/core/Video.vue'));

const props = defineProps<{
    enableFavoritedByBadge: boolean;
    details: v4.Products.ProductDetails;
    selectedVariant: v4.Products.ProductDetailsVariant;
    media: Media;
    isVideo: boolean;
    width: number;
    height: number;
    widthOnScreen: any;
    index: number;
}>();

const showFavoritedByBadge = computed(
    () =>
        props.enableFavoritedByBadge &&
        'favoritedBy' in props.details &&
        typeof props.details.favoritedBy === 'number' &&
        props.index === 0
);

const { shouldPlayVideo, togglePlayVideo, activeIndex } = useVideo(props.selectedVariant.sortedMedia);

const handleImageClick = () => {
    if (shouldPlay.value) return;
    activeIndex.value = props.index;
    togglePlayVideo(false);
    bus.emit(PDP_IMAGE_FULLSCREEN, props.index);
};

const shouldPlay = computed(() => {
    return props.index === activeIndex.value && shouldPlayVideo.value;
});

const handlePlayVideoClick = () => {
    activeIndex.value = props.index;
    togglePlayVideo(true);
};

const handleVideoStreamEnded = () => {
    togglePlayVideo(false);
};
</script>

<style scoped>
.aspect-ratio-3-4 {
    aspect-ratio: 3 / 4;
}
</style>
