<template>
    <div :class="{'min-h-screen': forcedMode}">
        <div class="sr-only">
            <!-- Used for better ARIA support -->
            {{ $translate('culture.Picked') }}: {{ currentCultureName }}
        </div>
        <!-- min-h-screen to make cookie banner scrollable -->
        <portal-overlay ref="overlay"
                        portal="overlay"
                        :show.sync="overlayOpen"
                        :disable-body-scroll="disableBodyScroll"
                        :show-close-button="!forcedMode"
                        :disable-user-close="forcedMode"
                        :show-blind="false"
                        :aria-label="$translate('generic.AriaLabels.CulturePicker')"
                        side="right">
            <section class="bg-white-100 min-h-screen flex flex-col justify-center relative z-3">
                <div class="logo-wrapper py-15 text-center" role="banner">
                    <c-icon name="logo" class="w-120"/>
                </div>
                <section class="o-container max-w-1000 mt-20 md:mt-60 text-center">
                    <div class="relative px-20">
                        <h1 class="uppercase font-brandon font-normal leading-title" v-html="titleWithBrTags"/>
                    </div>
                    <!--h2 class="mt-40 text-16 font-light">
                        {{ $translate('culture.Picked') }}: {{ currentCultureName }}
                    </h2-->
                    <ul class="flex flex-wrap cultures-wrapper mt-40 pt-20 text-left">
                        <li v-for="culture in cultures" :key="culture.isoCode" class="w-1/2 md:w-1/3 text-16 md:text-18 uppercase culture-item">
                            <!-- Must be anchor element - we want to make a hard-redirect to get new labels etc. -->
                            <a :href="culture.url" class="block mb-20 text-center" :class="{'text-white-350': isCurrent(culture)}">
                                <div class="font-brandon font-medium leading-title text-16 sm:text-18 md:text-20"><span class="effect-underline">{{ culture.name }}</span></div>
                                <span class="text-10">{{ culture.language }}</span>
                            </a>
                        </li>
                    </ul>
                </section>
            </section>
        </portal-overlay>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import cultureService from './culture.service';
import bus from '@/core/bus';
import { openCulturePickerEventName } from './constants';
import { ServerCultureModel } from '@/types/serverContract';
import { newLineToBr } from '@/project/shared/string.util';
import translateFilter from '@/core/translation/translate.filter';

@Component
export default class CulturePicker extends Vue {
    overlayOpen = false;
    forcedMode = false;

    mounted() {
        if (!cultureService.serverCulture()) {
            // root page
            if (!cultureService.getCookieCulture()) {
                // User has not selected culture - force her.
                this.forcedMode = true;
                this.overlayOpen = true;
            } else {
                // No culture from server, but we have users saved preference - use that
                window.location.href = cultureService.getCookieCulture()!.url;
            }
        } else {
            // Listen for explitic culture-selection
            // eslint-disable-next-line no-return-assign
            bus.on(openCulturePickerEventName, () => this.overlayOpen = true);
        }
    }

    get currentCultureName(): string | null {
        const currentCulture = cultureService.serverCulture();
        return currentCulture ? currentCulture.name : '';
    }

    isCurrent(culture: ServerCultureModel) : boolean {
        return culture === cultureService.serverCulture();
    }

    get cultures(): ServerCultureModel[] {
        return cultureService.serverCultures();
    }

    get titleWithBrTags() : string {
        return newLineToBr(translateFilter('culture.PickCulture'));
    }

    get disableBodyScroll(): boolean {
        // iPhone has an issue with scroll in cookie information banner when showing culture picker in force mode.
        return !this.forcedMode;
    }
}
</script>

<style scoped lang="less">

    .logo-wrapper {
        // border-bottom: 1px solid rgba(80, 65, 57, 0.1);
    }

    .cultures-wrapper {
        // border-top: 1px solid rgba(80, 65, 57, 1);
        column-count: 1;
    }
    .culture-item {
        column-break-inside: avoid;
    }

    @screen md {
        .cultures-wrapper {
            column-count: 3;
        }
    }
</style>
